import React from "react";
import { Container, Label } from "reactstrap";
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { BsCircle } from 'react-icons/bs'
import { FaCircle } from 'react-icons/fa'

import { IoIosWarning, IoIosSearch } from 'react-icons/io'
import ReactApexChart from 'react-apexcharts';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";
import Header from "../Header";
const Overview = () => {

    const nitrateState = {
        series: [40, 10, 10, 10],
        options: {
            labels: ["Primary",],

            plotOptions: {

                pie: {
                    show: true,
                    showAlways: true,
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                fontWeight: 700,
                                show: true,
                                label: '20%',
                                formatter: () => 'FULL',

                                fontSize: 15,
                                //    label:'Last refill Date'
                            },

                        },
                        size: '80%',
                    },

                },

            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
            },
            colors: ['#263238', '#cfd8dc', '#cfd8dc', '#cfd8dc', '#cfd8dc', '#cfd8dc', '#cfd8dc', '#cfd8dc'],
            plugins: {
                title: {
                    display: true,
                    text: 'Doughnut Chart',
                    color: 'blue',
                    font: {
                        size: 34
                    },
                    padding: {
                        top: 30,
                        bottom: 30
                    },
                    responsive: true,
                    animation: {
                        animateScale: true,
                    }
                }
            }

        },


    }
    const greenState = {
        series: [40, 10, 10, 10],
        options: {
            labels: ['Secondary'],

            plotOptions: {

                pie: {
                    show: true,
                    showAlways: true,
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                fontWeight: 700,
                                show: true,
                                label: `20% `,
                                formatter: () => 'FULL',

                                fontSize: 15,
                                color: '#598d00'
                                //    label:'Last refill Date'
                            },
                        },
                        size: '80%',
                    },

                },

            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
            },
            colors: ['#598d00', '#d7e4c2', '#d7e4c2', '#d7e4c2', '#d7e4c2', '#d7e4c2', '#d7e4c2', '#d7e4c2'],


        }
    }
    const data = [
        {
            name: "MON",
            Value1: 0,
            Value2: 50,
            Value3: 100,
            Value4: 150,
            Value5: 200,


        },
        {
            name: "TUE",
            Value1: 0,
            Value2: 50,
            Value3: 100,
            Value4: 150,
            Value5: 200,


        },
        {
            name: "WED",
            Value1: 0,
            Value2: 50,
            Value3: 100,
            Value4: 150,
            Value5: 200,


        },
        {
            name: "THU",
            Value1: 0,
            Value2: 50,
            Value3: 100,
            Value4: 150,
            Value5: 200,


        },
        {
            name: "FRI",
            Value1: 0,
            Value2: 50,
            Value3: 50,
            Value4: 100,
            Value5: 150,
            Value6: 200
        },
        {
            name: "SAT",
            Value1: 0,
            Value2: 50,
            Value3: 100,
            Value4: 150,
            Value5: 200,


        },
        {
            name: "SUN",
            Value1: 0,
            Value2: 50,
            Value3: 100,
            Value4: 150,
            Value5: 200,


        }
    ];
    const state = {

        series: [{
            name: 'REAL - TIME NUTRIENT LEVELS',
            data: [150, 250, 5, 125,]
        } ],
        options: {
          
            colors: ['#598d00'],
            chart: {
                type: 'bar',
                stacked: true,
                toolbar: {
                    show: true
                },
                toolbar: {
                    show: false,
                },
                // foreColor: 'red'
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '30%',
                    colors: {
                        backgroundBarColors: ['#d7e4c2'],
                    },
                    barHeight: '80%',
                },
              
            },
            xaxis: {
                type: 'category',
                categories: ['Nitarte (NO3)', 'Patassium (K)', 'Iron (Fe)', 'Calcium (Ca)',
                ],
            },
            yaxis: {
                show: true,
                showAlways: true,
                tickAmount: 3,
                tooltip: {
                    enabled: true,
                    offsetX: 0,
                },

            },
           
          
        },


    };

    return (
        <React.Fragment>
            <Header />

            <div className="mt-3  input-wrapper  " style={{ marginLeft: 850 }}>
                <input
                    className="col-12 px-3 px-5"
                    onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                    onBlur={(e) => e.target.style.outlineColor = ''}
                    style={{
                        height: 25,
                        backgroundColor: '#f7f7f5',
                        borderRadius: 10,
                        border: 'none',
                        paddingLeft: 10,

                    }}
                    type="search"
                    placeholder="Search..."
                />
                <div style={{ marginTop: -25, }} className="  px-3">
                    <IoIosSearch size={20} color={'#838e91'} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6  col-lg-6 ">
                    <div className="my-4" style={{ backgroundColor: '#f7f7f5', borderRadius: 5 }}>
                        <div className="d-flex justify-content-between pt-2">
                            <p className="mx-2" style={{ fontWeight: 500, fontSize: 14 }}>NUTRIENT WATER</p>
                            <BiDotsHorizontalRounded className="mx-2" />
                        </div>
                        <div className="d-flex justify-content-around ">
                            <div >
                                <p><span style={{ fontSize: 12 }} >pH</span> <span className="mx-2"><b style={{ color: '#598D00' }}> 5.8</b> </span></p>
                                <p className="mx-2" style={{ marginTop: -20 }}><span style={{ fontSize: 10, marginTop: -10 }} className="mx-4"> Good</span></p>
                            </div>
                            <div className="border-end "></div>
                            <div >
                                <p ><span style={{ fontSize: 12 }}>EC</span> <span ><b className="mx-2" style={{ color: '#598D00' }}> 1.9</b> </span></p>
                                <p style={{ marginTop: -20 }}><IoIosWarning color='#fbc386' /><span style={{ fontSize: 10, color: '#fbc386' }} className="mx-1"> Low</span></p>
                            </div>
                            <div className="border-end "></div>
                            <div >
                                <p> <span style={{ fontSize: 12 }}>Temp</span> <span  ><b className="mx-2" style={{ color: '#598D00' }}> 67 <sup style={{ left: -4 }}><BsCircle size={5} /></sup></b> </span></p>
                                <p style={{ marginTop: -20 }}><IoIosWarning color="#fbafae" /><span style={{ fontSize: 10, color: '#fbafae' }} className="mx-3"> High</span></p>
                            </div>
                            <div className="border-end "></div>
                            <div >
                                <p><span style={{ fontSize: 12 }}>DO</span> <span className="mx-2"><b style={{ color: '#598D00' }}> 19.3</b> </span></p>
                                <p className="mx-2" style={{ marginTop: -20 }}><span style={{ fontSize: 10, marginTop: -10 }} className="mx-4"> Good</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="my-4" style={{ backgroundColor: '#f7f7f5', borderRadius: 5 }}>

                        <div className="d-flex justify-content-around pt-2">
                            <p style={{ marginLeft: -15, fontWeight: 500, fontSize: 14 }} >   TANK LEVELS</p>
                            <p style={{ marginLeft: 96, fontSize: 12, color: '#7F7F7F' }} > <span className="mx-2"><FaCircle size={12} color={'#263238'} /></span>Primary</p>
                            <p style={{ fontSize: 12, color: '#7F7F7F' }}> <span className="mx-2"><FaCircle size={12} color={'#598d00'} /></span>Secondary</p>
                            <BiDotsHorizontalRounded className="mx-2" />
                        </div>
                         <div className="row mx-3">
                            <div className=" col-lg-3 "> <ReactApexChart options={nitrateState.options} series={nitrateState.series} type="donut" height="150" /></div>
                            <div className=" col-lg-3"> <ReactApexChart options={greenState.options} series={greenState.series} type="donut" height="150" /></div>
                            <div className=" col-lg-3"> <ReactApexChart options={nitrateState.options} series={nitrateState.series} type="donut" height="150" /></div>
                            <div className=" col-lg-3"> <ReactApexChart options={greenState.options} series={greenState.series} type="donut" height="150" /></div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6  col-lg-6 my-4 " >

                    <div style={{ backgroundColor: '#f7f7f5', borderRadius: 5,marginRight:5 }}>
                        {/* w-100 */}
                        <div className="d-flex justify-content-between pt-2">
                            <p style={{ fontWeight: 500, fontSize: 14 }} className="mx-3">REAL-TIME NUTRIENT LEVELS</p>
                            <BiDotsHorizontalRounded className="mx-2" />
                        </div>
                        <div>
                            <ReactApexChart options={state.options} series={state.series} type="bar" height={245} />


                            {/* <table className="  mt-1 w-100 " style={{ marginLeft:40 }}>

                                <tr >
                                    <td className='px-4 py-1'>
                                        <Label style={{ fontSize: 12, color: '#7F7F7F', fontWeight: 500 }} >Nitrate<span>(NO<sub>3</sub>)</span></Label>
                                    </td>
                                    <td className='px-4 py-1'>
                                        <input
                                            className=" px-3 my-2"
                                            style={{ width: 60 }}
                                            onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                                            onBlur={(e) => e.target.style.outlineColor = ''}
                                            id="NoTanks"
                                            placeholder="170"
                                            type="text"
                                        // onChange={handleText}
                                        />
                                    </td>
                                </tr>
                                <tr >
                                    <td className='px-4 py-1'>
                                        <Label style={{ fontSize: 12, color: '#7F7F7F', fontWeight: 500 }}>Potassium<spa>(K)</spa></Label>
                                    </td>
                                    <td className='px-4 py-1'>
                                        <input
                                            style={{ width: 60 }}
                                            className=" px-3 my-2"
                                            onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                                            onBlur={(e) => e.target.style.outlineColor = ''}
                                            id="NoTanks"
                                            placeholder="220"
                                            type="text"
                                        // onChange={handleText}
                                        />
                                    </td>
                                </tr>
                                <tr >
                                    <td className='px-4 py-1'>
                                        <Label style={{ fontSize: 12, color: '#7F7F7F', fontWeight: 500 }}>Calcium<span>(Fe)</span></Label>
                                    </td>
                                    <td className='px-4 py-1'>
                                        <input
                                            style={{ width: 60 }}
                                            className=" px-3 my-2"
                                            onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                                            onBlur={(e) => e.target.style.outlineColor = ''}
                                            id="NoTanks"
                                            placeholder="10"
                                            type="text"
                                        // onChange={handleText}
                                        />
                                    </td>
                                </tr>
                                <tr >
                                    <td className='px-4 py-1'>
                                        <Label style={{ fontSize: 12, color: '#7F7F7F', fontWeight: 500 }}>Phosphate</Label>
                                    </td>
                                    <td className='px-4 py-1' >
                                        <input
                                            style={{ width: 60 }}
                                            className=" px-3 my-2"
                                            onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                                            onBlur={(e) => e.target.style.outlineColor = ''}
                                            id="NoTanks"
                                            placeholder="50"
                                            type="text"
                                        // onChange={handleText}
                                        />
                                    </td>
                                </tr>
                            </table> */}
                        </div>

                    </div>

                </div>
            </div>
            <div className="mt-2 pt-2" style={{ backgroundColor: '#f7f7f5', borderRadius: 5 }}>
                <div className="d-flex justify-content-between">
                    <p className="mx-3" style={{ fontWeight: 500, fontSize: 14 }}>NUTRIENT </p>


                    <BiDotsHorizontalRounded className="mx-2" />
                </div>
                <div className="d-flex justify-content-end " style={{ marginRight: 141 }}>
                    <p style={{ fontSize: 10, color: '#7F7F7F' }} className="mx-2"> <span className="mx-2"><FaCircle size={12} color={'#263238'} /></span>Value(01)</p>
                    <p style={{ fontSize: 10, color: '#7F7F7F' }} className="mx-2"> <span className="mx-2"><FaCircle size={12} color={'#598d00'} /></span>Value(02)</p>
                    <p style={{ fontSize: 10, color: '#7F7F7F' }} className="mx-2"> <span className="mx-2"><FaCircle size={12} color={'#d4e462'} /></span>Value(03)</p>
                </div>
                <div className="row">
                    <div className="col-12">
                        <BarChart
                            width={1050}
                            height={300}
                            data={data}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            {/* <Tooltip /> */}
                            {/* <Legend iconType="circle" verticalAlign='top' align='right' wrapperStyle={{ top: -35, }} /> */}
                            <Bar dataKey="Value1" stackId="a" fill="#598d00" />
                            <Bar dataKey="Value2" stackId="b" fill="#98b762" />
                            <Bar dataKey="Value3" stakId="c" fill="#d4e462" />
                            <Bar dataKey="Value4" stackId="d" fill="#598d00" />
                            <Bar dataKey="Value5" stackId="e" fill="#3d4f54" />
                            <Bar dataKey="Value6" stackId="f" fill="#d4e462" />


                        </BarChart>
                    </div>

                    {/* <ReactApexChart options={nitrateState.options} series={nitrateState.series} type="donut" height="200"  /> */}

                </div>
            </div>
        </React.Fragment>

    )
}
export default Overview
import React, { useState } from "react";
import {
    Label,
   
} from 'reactstrap'


const PPMLevels = () => {
    return (
           <>
            <h5 className="text-center mt-2 " >PPM Levels </h5>
            <div className="d-flex justify-content-center" >
                {/* w-100 */}
                <table className="  mt-1 w-100">

                    <tr className="my-5 " >
                        <td style={{ color: '#598D00', fontSize: 13, fontWeight: 500, paddingLeft: 126 }} className=' py-3' >Monitored Nutrients </td>
                        <td style={{ color: '#598D00', fontSize: 13, fontWeight: 500, paddingRight: 100  }} className='py-3'  >Target Level </td>
                    </tr>
                    <tr className='border-bottom border-secondary'>
                        <td className=' py-3' style={{ paddingLeft: 126,   }}>
                            <Label style={{ color: '#506065', fontWeight: 500 }}>Nitrate</Label>
                        </td>
                        <td className=' py-3' >
                            <input
                                className=" px-3 my-2"
                                style={{   width: 80, borderWidth: 1 }}
                                
                                onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                                onBlur={(e) => e.target.style.outlineColor = ''}
                                id="NoTanks"
                                placeholder="170"
                                type="text"
                            // onChange={handleText}
                            />
                        </td>
                    </tr>
                    <tr className='border-bottom border-secondary'>
                        <td className=' py-3' style={{ paddingLeft: 126 }}>
                            <Label style={{ color: '#506065', fontWeight: 500  }}>Potassium</Label>
                        </td>
                        <td className=' py-3'>
                            <input
                                style={{   width: 80, borderWidth: 1 }}
                                className=" px-3 my-2"
                                onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                                onBlur={(e) => e.target.style.outlineColor = ''}
                                id="NoTanks"
                                placeholder="220"
                                type="text"
                            // onChange={handleText}
                            />
                        </td>
                    </tr>
                    <tr className='border-bottom border-secondary'>
                        <td className=' py-3' style={{ paddingLeft: 126 }}>
                            <Label style={{ color: '#506065', fontWeight: 500  }}>Calcium</Label>
                        </td>
                        <td className=' py-3'>
                            <input
                                style={{   width: 80, borderWidth: 1 }}
                                className=" px-3 my-2"
                                onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                                onBlur={(e) => e.target.style.outlineColor = ''}
                                id="NoTanks"
                                placeholder="10"
                                type="text"
                            // onChange={handleText}
                            />
                        </td>
                    </tr>
                    <tr >
                        <td className=' py-3' style={{ paddingLeft: 126 }}>
                            <Label style={{ color: '#506065', fontWeight: 500  }}>Phosphate</Label>
                        </td>
                        <td className=' py-3' >
                            <input
                                style={{   width: 80, borderWidth: 1, }}
                                className=" px-3 my-2"
                                onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                                onBlur={(e) => e.target.style.outlineColor = ''}
                                id="NoTanks"
                                placeholder="50"
                                type="text"
                            // onChange={handleText}
                            />
                        </td>
                    </tr>
                </table>
            </div>
           </>
         
    );
};
export default PPMLevels
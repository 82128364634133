import React from "react";
import Header from "../Header";

const Nutrients = () => {
    return (
        <div>
            <Header/>
            <b>Nutrients</b>
        </div>
    )
}
export default Nutrients

import React from "react";
import { Container } from "reactstrap";
import Header from "./Header";
import SideBar from "./SideBar";
import { useLocation, Outlet, useParams, useNavigate } from 'react-router-dom'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
const MasterView = () => {
    return (
        <React.Fragment>
            <Container fluid >
                <div className="row ">
                    <div className=" col-2  mt-0 p-0  " style={{ width: '12%',}}>
                        {/* className="max-vh-50  "  overflowY: 'scroll', overflowX:'hidden',*/}
                        <div className="   position-fixed max-vh-50  "
                            style={{ maxHeight: '100vh', overflowX: 'hidden', width: '12%'  }}
                        >
                            <SideBar />
                          </div>
                           
                        
                    </div>
                    <div className="col-10" style={{ width: '88%', }}>
                           
                            <Outlet />
                       
                    </div>
                   
                </div>
            </Container>

        </React.Fragment>
    );

}
export default MasterView

import React, { useState } from "react";
import {
    Form,
    Label,
    Input,
    Col,
    Row,
    Container,
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap'
const Success = () => {
    return (
        <>
            <h3 className="text-center mt-3 "  >Configuration Success ! </h3>
            <div className="d-flex justify-content-center" >
                {/* w-100 */}
                <table className="  mt-5 w-100">

                    <tr className="my-5">
                        <td style={{ color: '#598D00', fontWeight: 500,fontSize:15  }} className='px-4 py-2' >Monitored Nutrients </td>
                        <td style={{ color: '#598D00', fontWeight: 500,fontSize:15 }} className='px-4 py-2' >Target Level </td>
                        <td style={{ color: '#598D00', fontWeight: 500,fontSize:15 }} className='px-4 py-2' >Residing Tank </td>
                    </tr>
                    <tr className='border-bottom border-secondary '>
                        <td className='px-5 py-2'>
                            <Label style={{ color:'#7F7F7F', fontWeight:500 }} > Nitrate</Label>
                        </td>
                        <td className='px-5 py-2'>
                            <Label style={{ color:'#7F7F7F', fontWeight:500 }}>150 ppm</Label>
                        </td>
                        <td className='px-5 py-2'>
                            <Label style={{ color:'#7F7F7F', fontWeight:500 }}>1</Label>
                        </td>
                    </tr>
                    <tr className='border-bottom border-secondary'>
                        <td className='px-5 py-2'>
                            <Label style={{ color:'#7F7F7F', fontWeight:500 }}>Potassium</Label>
                        </td>
                        <td className='px-5 py-2'>
                            <Label style={{ color:'#7F7F7F', fontWeight:500 }}>250 ppm</Label>
                        </td>
                        <td className='px-5 py-2'>
                            <Label style={{ color:'#7F7F7F', fontWeight:500 }}>2</Label>
                        </td>
                    </tr>
                    <tr className='border-bottom border-secondary'>
                        <td className='px-5 py-2'>
                            <Label style={{ color:'#7F7F7F', fontWeight:500 }}>Calcium</Label>
                        </td>
                        <td className='px-5 py-2'>
                            <Label style={{ color:'#7F7F7F', fontWeight:500 }}>5 ppm</Label>
                        </td>
                        <td className='px-5 py-2'>
                            <Label style={{ color:'#7F7F7F', fontWeight:500 }}>3</Label>
                        </td>
                    </tr>
                    <tr >
                        <td className='px-5 py-2'>
                            <Label style={{ color: '#7F7F7F', fontWeight: 500 }}>Phosphate</Label>
                        </td>
                        <td className='px-5 py-2' >
                            <Label style={{ color:'#7F7F7F', fontWeight:500 }}>125 ppm</Label>
                        </td>
                        <td className='px-5 py-2'>
                            <Label style={{ color:'#7F7F7F', fontWeight:500 }}>4</Label>
                        </td>
                    </tr>
                </table>
            </div>
        </>
    );
};
export default Success
import React from "react";
import { BsPlusCircleFill } from 'react-icons/bs';
import { Container } from "reactstrap";
import ReactApexChart from 'react-apexcharts';
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import Header from "../Header";
import {  IoIosSearch } from 'react-icons/io';
const Tanks = () => {

    const blackState = {
        series: [10, 10, 10, 10, 10, 10, 10,],

        options: {
            labels: ["Nitrate", "Empty", "Empty", "Empty", "Empty", "Empty", "Empty"],
            title: {
                text: 'Last refill date: ',
                // align: 'left',
               
                offsetX: 45,
                offsetY: 135,
                floating: false,
                style: {
                    fontSize: '8px',
                    // fontWeight: 'bold',
                    // fontFamily: undefined,
                    color: '#7F7F7F'
                },
            },
            subtitle: {
                text: '12/18/2021',
                // align: 'left',
                offsetX: 55,
                offsetY: 145,
                floating: false,
                style: {
                    fontSize: '8px',
                    fontWeight: 'bold',
                    color: '#7F7F7F'
                },
            },
            plotOptions: {
               

                pie: {
                    show: true,
                    showAlways: true,
                    donut: {
                        size: '80%',
                        labels: {
                            show: true,
                            total: {
                                fontWeight: 700,
                                show: true,
                                label: `20% `,
                                formatter: () => 'FULL',
                                fontSize: 30,
                                color: 'black',
                            },
                           
                        },
                        


                    },
                    

                },

            },

            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
            },
            colors: ['#263238', '#cfd8dc', '#cfd8dc', '#cfd8dc', '#cfd8dc', '#cfd8dc', '#cfd8dc', '#cfd8dc'],


        }
      
    }
    const greenState = {
        series: [40, 10, 10, 10],
        options: {
            labels: ["Nitrate", "Empty", "Empty", "Empty", "Empty", "Empty", "Empty"],
            title: {
                text: 'Last refill date: ',
                // align: 'left',

                offsetX: 45,
                offsetY: 135,
                floating: false,
                style: {
                    fontSize: '8px',
                    // fontWeight: 'bold',
                    // fontFamily: undefined,
                    color: '#598d00'
                },
            },
            subtitle: {
                text: '12/18/2021',
                // align: 'left',
                offsetX: 55,
                offsetY: 145,
                floating: false,
                style: {
                    fontSize: '8px',
                    fontWeight: 'bold',
                    color: '#598d00'
                },
            },
            plotOptions: {
             
                pie: {
                    show: true,
                    showAlways: true,
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                fontWeight: 700,
                                show: true,
                                label: `20% `,
                                formatter: () => 'FULL',

                                fontSize: 30,
                                color: '#598d00'
                                //    label:'Last refill Date'
                            },
                        },
                        size: '80%',
                    },

                },

            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false,
            },
            colors: ['#598d00', '#d7e4c2', '#d7e4c2', '#d7e4c2', '#d7e4c2', '#d7e4c2', '#d7e4c2', '#d7e4c2'],


        }
    }
  
    return (
        <React.Fragment>
            {/* <Container> */}
                <Header/>
                <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-start mt-3 mb-3">
                        <p style={{ color: '#3d4f54', marginRight: 10,marginLeft:15, fontWeight:500 ,fontSize:13  }} >Add NEW TANK</p>
                        <i>
                            <BsPlusCircleFill style={{ color: '#3d4f54', }} />
                        </i>
                    </div>
                <div className="my-3  input-wrapper  " >
                    <input
                        className="col-12 px-3 px-5"
                        onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                        onBlur={(e) => e.target.style.outlineColor = ''}
                        style={{
                            height: 25,
                            backgroundColor: '#f7f7f5',
                            borderRadius: 10,
                            border: 'none',
                            paddingLeft: 10,

                        }}
                        type="search"
                        placeholder="Search..."
                    />
                    <div style={{ marginTop: -25, }} className="  px-3">
                        <IoIosSearch size={20} color={'#838e91'} />
                    </div>
                </div>
                </div>
                <div className="row mt-1">
                    <div style={{ backgroundColor: '#f7f7f5' }} className='col-xs 6 col-md-6   col-lg-2 mx-3'>
                        <div className="d-flex justify-content-between">
                            <div>
                                <b style={{ fontSize: 10 }}>TANK A</b>
                                <p style={{ fontSize: 12, fontWeight:500 }}>Nitrate (NO<sub>3</sub>)</p>
                            <p style={{ fontSize: 8, color: '#7F7F7F', }}>DOSAGE RATE: <span style={{ fontSize: 12, fontWeight: 900, color: '#7F7F7F', marginLeft: 10 }}>200</span></p>
                            </div>
                            <div >
                                <BiDotsHorizontalRounded className="mx-2" />
                            </div>
                        </div>


                    <div style={{ marginTop: -24 }}>
                            <ReactApexChart options={blackState.options} series={blackState.series} type="donut" height="190" />
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#f7f7f5' }} className='col-xs 6 col-md-6  col-lg-2 mx-3'>
                        <div className="d-flex justify-content-between">
                            <div>
                                <b style={{ color: '#5f7d52', fontSize: 10 }}>TANK B</b>
                            <p style={{ color: '#598d00', fontSize: 12, fontWeight: 500 }}>Potassium (K)</p>
                            <p style={{ fontSize: 8, color: '#598d00' }}>DOSAGE RATE: <span style={{ fontSize: 12, fontWeight: 900, color: '#598d00', marginLeft: 10 }}>200</span></p>
                            </div>
                            <div>
                                <BiDotsHorizontalRounded className="mx-2" />
                            </div>
                        </div>
                    <div style={{ marginTop: -24 }}>
                            <ReactApexChart options={greenState.options} series={greenState.series} type="donut" height="190" />
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#f7f7f5' }} className='col-xs 6 col-md-6  col-lg-2 mx-3'>
                        <div className="d-flex justify-content-between">
                            <div>
                                <b style={{ fontSize: 10 }}>TANK C</b>
                            <p style={{ fontSize: 12, fontWeight: 500 }}>Phosphate (PO<sub>4</sub>)</p>
                            <p style={{ fontSize: 8, color: '#7F7F7F', }}>DOSAGE RATE: <span style={{ fontSize: 12, fontWeight: 900, color: '#7F7F7F', marginLeft: 10 }}>200</span></p>
                            </div>
                            <div>
                                <BiDotsHorizontalRounded className="mx-2" />
                            </div>
                        </div>
                    <div style={{ marginTop: -24 }}>
                            <ReactApexChart options={blackState.options} series={blackState.series} type="donut" height="190" />
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#f7f7f5' }} className='col-xs 6 col-md-6  col-lg-2 mx-3'>
                        <div className="d-flex justify-content-between">
                            <div>
                                <b style={{ color: '#5f7d52', fontSize: 10 }}>TANK D</b>
                            <p style={{ color: '#598d00', fontSize: 12, fontWeight: 500 }}>Calcium (Ca)</p>
                            <p style={{ fontSize: 8, color: '#598d00' }}>DOSAGE RATE: <span style={{ fontSize: 12, fontWeight: 900, color: '#598d00', marginLeft: 10 }}>200</span></p>
                            </div>
                            <div>
                                <BiDotsHorizontalRounded className="mx-2" />
                            </div>
                        </div>
                    <div style={{ marginTop: -24 }}>
                            <ReactApexChart options={greenState.options} series={greenState.series} type="donut" height="190" />
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#f7f7f5' }} className='col-xs 6 col-md-6  col-lg-2 mx-3'>
                        <div className="d-flex justify-content-between">
                            <div>
                                <b style={{ fontSize: 10 }}>TANK E</b>
                            <p style={{ fontSize: 12, fontWeight: 500 }}>Ammonium (NH<sub>3</sub>)</p>
                            <p style={{ fontSize: 8, color: '#7F7F7F', }}>DOSAGE RATE: <span style={{ fontSize: 12, fontWeight: 900, color: '#7F7F7F', marginLeft: 10 }}>200</span></p>
                            </div>
                            <div>
                                <BiDotsHorizontalRounded className="mx-2" />
                            </div>
                        </div>
                    <div style={{ marginTop: -24 }}>
                            <ReactApexChart options={blackState.options} series={blackState.series} type="donut" height="190" />
                        </div>
                    </div>


                </div>
                <div className="row my-5 ">
                    <div style={{ backgroundColor: '#f7f7f5' }} className='col-xs 6 col-md-6   col-lg-2 mx-3'>
                        <div className="d-flex justify-content-between">
                            <div>
                                <b style={{ fontSize: 10 }}>TANK E</b>
                            <p style={{ fontSize: 12, fontWeight: 500 }}>Ammonium (NH<sub>3</sub>)</p>
                            <p style={{ fontSize: 8, color: '#7F7F7F', }}>DOSAGE RATE: <span style={{ fontSize: 12, fontWeight: 900, color: '#7F7F7F', marginLeft: 10 }}>200</span></p>
                            </div>
                            <div>
                                <BiDotsHorizontalRounded className="mx-2" />
                            </div>
                        </div>
                        <div style={{ marginTop:-24 }}>
                            <ReactApexChart options={blackState.options} series={blackState.series} type="donut" height="190" />
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#f7f7f5' }} className='col-xs 6 col-md-6  col-lg-2 mx-3'>
                        <div className="d-flex justify-content-between">
                            <div>
                                <b style={{ color: '#5f7d52', fontSize: 10 }}>TANK F</b>
                            <p style={{ color: '#598d00', fontSize: 12, fontWeight: 500 }}>Iron (Fe)</p>
                            <p style={{ fontSize: 8, color: '#598d00' }}>DOSAGE RATE: <span style={{ fontSize: 12, fontWeight: 900, color: '#598d00', marginLeft: 10 }}>200</span></p>
                            </div>
                            <div>
                                <BiDotsHorizontalRounded className="mx-2" />
                            </div>
                        </div>
                    <div style={{ marginTop: -24 }}>
                            <ReactApexChart options={greenState.options} series={greenState.series} type="donut" height="190" />
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#f7f7f5' }} className='col-xs 6 col-md-6  col-lg-2 mx-3'>
                        <div className="d-flex justify-content-between">
                            <div>
                                <b style={{ fontSize: 10 }}>TANK G</b>
                            <p style={{ fontSize: 12, fontWeight: 500 }}>Hydrogen </p>
                            <p style={{ fontSize: 8, color: '#7F7F7F', }}>DOSAGE RATE: <span style={{ fontSize: 12, fontWeight: 900, color: '#7F7F7F', marginLeft: 10 }}>200</span></p>
                            </div>
                            <div>
                                <BiDotsHorizontalRounded className="mx-2" />
                            </div>
                        </div>
                    <div style={{ marginTop: -24 }}>
                            <ReactApexChart options={blackState.options} series={blackState.series} type="donut" height="190" />
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#f7f7f5' }} className='col-xs 6 col-md-6  col-lg-2 mx-3'>
                        <div className="d-flex justify-content-between">
                            <div>
                                <b style={{ fontSize: 10 }}>TANK H</b>
                            <p style={{ fontSize: 12, fontWeight: 500 }}>Peroxide (H<sub>2</sub>O<sub>2</sub>)</p>
                            <p style={{ fontSize: 8, color: '#7F7F7F', }}>DOSAGE RATE: <span style={{ fontSize: 12, fontWeight: 900, color: '#7F7F7F', marginLeft: 10 }}>200</span></p>
                            </div>
                            <div>
                                <BiDotsHorizontalRounded className="mx-2" />
                            </div>
                        </div>
                    <div style={{ marginTop: -24 }}>
                            <ReactApexChart options={blackState.options} series={blackState.series} type="donut" height="190" />
                        </div>
                    </div>
                    <div style={{ backgroundColor: '#f7f7f5' }} className='col-xs 6 col-md-6  col-lg-2 mx-3'>
                        <div className="d-flex justify-content-between">
                            <div>
                                <b style={{ fontSize: 10 }}>TANK G</b>
                            <p style={{ fontSize: 12, fontWeight: 500 }}>Hydrogen </p>
                            <p style={{ fontSize: 8, color: '#7F7F7F', }}>DOSAGE RATE: <span style={{ fontSize: 12, fontWeight: 900, color: '#7F7F7F', marginLeft: 10 }}>200</span></p>
                            </div>
                            <div>
                                <BiDotsHorizontalRounded className="mx-2" />
                            </div>
                        </div>
                    <div style={{ marginTop: -24 }}>
                            <ReactApexChart options={blackState.options} series={blackState.series} type="donut" height="190" />
                        </div>
                    </div>


                </div>
            {/* </Container> */}
        </React.Fragment>


    )
}
export default Tanks
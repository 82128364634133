import React from "react";
import { NavLink, useLocation, useNavigate, Link, Outlet } from "react-router-dom";
import { BsArrowRightShort } from 'react-icons/bs'

import { AiOutlineEyeInvisible, AiOutlineEye, } from 'react-icons/ai'
import './usageStyle.css'
import {
    Form,
    Label,
    Input,
    Col,
    Row,
    Container,
    Button
} from 'reactstrap'
const Usage = () => {
    const location = useLocation()
    const navigate = useNavigate
    return (
        <div>
            <div className="d-flex justify-content-between m-2 ">
                <p >EQM</p>
                <span style={{ fontSize: 12, marginRight:488  }}>Frederick, MD<i className="icon-EQM-Send mx-2 "></i></span>
            </div>
            <div>
                <Form >
                    <Row className='my-3 d-flex justify-content-center'>
                        <div className=' d-flex justify-content-center '
                            style={{ backgroundColor: '#f7f7f5', borderRadius: 10,padding:68 }}
                        >
                            <h3 style={{ marginTop: -53, fontSize: 20, fontWeight: 'bold'}} >Welcome to EQM</h3>


                        </div>
                        <Col xs={6} className='mt-5'>

                            <div className="mt-4 col-12">
                                <input
                                    className="col-12 px-3"
                                    style={{ backgroundColor: '#f7f7f5', borderRadius: 4, height: 35, border: '#738083 solid 1px', borderWidth: 1, outlineColor: '#7b878b' }}
                            
                                    onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                                    onBlur={(e) => e.target.style.outlineColor = '#7b878b'}
                                    id="name"
                                    placeholder="User Name"
                                    type="text"
                                // onChange={handleText}
                                />
                            </div>
                            <div className="mt-4">
                                <input
                                    className="col-12 px-3"
                                    style={{ backgroundColor: '#f7f7f5', borderRadius: 4, height: 35, border: '#738083 solid 1px', borderWidth: 1, outlineColor: '#7b878b' }}
                                    onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                                    onBlur={(e) => e.target.style.outlineColor = '#7b878b'}
                                    id="email"
                                    // value={email}
                                    placeholder="Email Address"
                                    type="email"
                                // onChange={handleText}
                                />
                            </div>

                            <div className="mt-4">
                                <input
                                    className="col-12 px-3"
                                    style={{ backgroundColor: '#f7f7f5', borderRadius: 4, height: 35, border: '#738083 solid 1px', borderWidth: 1, outlineColor: '#7b878b' }}
                                    onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                                    onBlur={(e) => e.target.style.outlineColor = '#7b878b'}
                                    id="password"
                                    // value={password}
                                    placeholder="Password"
                                    type="password"
                                // onChange={handleText}
                                />
                                <div style={{ marginTop: -28 }} className="d-flex justify-content-end px-3">
                                    <AiOutlineEye size={20} color={'#738083'} />
                                </div>


                            </div>
                            <div style={{marginTop:32}}>
                                <input
                                    className="col-12 px-3  "
                                    style={{ backgroundColor: '#f7f7f5', borderRadius: 4, height: 35, border: '#738083 solid 1px', borderWidth: 1, outlineColor: '#7b878b' }}
                                    onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                                    onBlur={(e) => e.target.style.outlineColor = '#7b878b'}
                                    id="password"
                                    // value={password}
                                    placeholder="Confirm Password"
                                    type="password"
                                // onChange={handleText}
                                />
                                <div style={{ marginTop: -28 }} className="d-flex justify-content-end px-3">
                                    <AiOutlineEyeInvisible size={20} color={'#738083'} />
                                </div>

                            </div>
                            <div className="mt-4" >
                                <input
                                    style={{
                                        accentColor: '#598D00',
                                         cursor: 'pointer',
                                    }}
                                    id="checkbox"
                                    // value={checkbox}
                                    type="checkbox"
                                // onChange={handleText}
                                />
                                <Label className=' mx-3' style={{  fontSize: 13 }}>Confirm Password</Label>
                            </div>

                            <div className="d-flex justify-content-center mt-4 ">
                                <Link to='/eqm/steps'>
                                    <Button
                                        style={{ backgroundColor: '#598D00', fontSize: 13 }}
                                        type="button"
                                        // onClick={navigate('/EQM/usage/steps')}
                                        className='px-5 py-2'
                                    >

                                        Continue
                                    </Button>
                                </Link>

                            </div>
                            <div className="border border-dark " style={{ marginTop:30 }}>

                           </div>
                            <div className='my-4' style={{ fontSize:13 }} >

                                <p className="text-muted">Already have an account ? {' '}
                                <NavLink to='#'
                                    style={{
                                        color: '#598D00',
                                        textDecoration: 'none',
                                    }} >
                                    Sign in
                                    <span>
                                        <BsArrowRightShort color="#598D00" size={20} />
                                    </span>
                                </NavLink></p>
                            </div>


                        </Col>
                    </Row>

                </Form>
            </div>

            {/* <Outlet/> */}
        </div>
    )
}
export default Usage


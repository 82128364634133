import React from "react";
import { Container } from "reactstrap";
import Usage from "../HeaderComponents/Usage";

const EQM = () =>{
    return(
        <React.Fragment>
            {/* <Container fluid> */}
                <Usage/>
            {/* </Container> */}
        </React.Fragment>
    )
}
export default EQM
import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { BsPlusCircleFill } from 'react-icons/bs';
import { IoIosArrowForward, IoIosArrowBack, IoIosSearch } from 'react-icons/io';
import { NavLink, useLocation } from "react-router-dom";
import ReactApexChart from 'react-apexcharts';
import Header from "../Header";
import ReactPaginate from 'react-paginate';
import './pagination.css'
const UsageStepsCompleted = () => {
    const location = useLocation();
    const [currentItems, setCurrentItems] = useState(null);
    const [whichTypeofCategory, setwhichTypeofCategory] = useState([])
    const [isShowDay, setIsShowDay] = useState(false)
    const [isShowWeek, setIsShowWeek] = useState(false)
    const [isShowMonth, setIsShowMonth] = useState(false)
    const [isShowYear, setIsShowYear] = useState(true)
    const [yearCounter, setYearCounter] = useState(2021)
    const [paginationCounter, setPaginationCounter]=useState('')
    const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    function Items({ currentItems }) {
        return (
            <>
                {currentItems &&
                    currentItems.map((item) => (
                        <div>
                            <h3>Item #{item}</h3>
                        </div>
                    ))}
            </>
        );
    }
    const handlePageClick = (event) => {
        setPaginationCounter(event.selected)
        
    };
    useEffect(() => {
        if (isShowMonth) {
            if (paginationCounter ==0){
                setwhichTypeofCategory(
                    ['JAN(01)', 'JAN(02)', 'JAN(03)', 'JAN(04)', 'JAN(05)', 'JAN(06)', 'JAN(07)',
                        'JAN(08)', 'JAN(09)', 'JAN(10)', 'JAN(11)', 'JAN(12)']
                )
            }
            else{
                setwhichTypeofCategory(
                    ['JAN(13)', 'JAN(14)', 'JAN(15)', 'JAN(16)', 'JAN(17)', 'JAN(18)', 'JAN(19)',
                        'JAN(20)', 'JAN(21)', 'JAN(22)', 'JAN(23)', 'JAN(24)']
                )
            }
           
        }

        else if (isShowYear) {
            setwhichTypeofCategory(
                ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL',
                    'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

            )
        }

        else if (isShowWeek) {
            setwhichTypeofCategory(
                ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI',]

            )
        }
        else if (isShowDay) {
            if (paginationCounter == 0){
                setwhichTypeofCategory(
                    ['01(hr)', '02(hr)', '03(hr)', '04(hr)', '05(hr)', '0(hr)', '07(hr)', '08(hr)', '09(hr)', '10(hr)', '11(hr)', '12(hr)']

                )
            }
            else{
                setwhichTypeofCategory(
                    ['13(hr)', '14(hr)', '15(hr)', '16(hr)', '17(hr)', '18(hr)', '19(hr)', '20(hr)', '21(hr)', '22(hr)', '23(hr)', '24(hr)']

                )
            }
           
        }
        else {
            console.log('not select url')
        }
    }, [location, paginationCounter]);       
    const mixed = {

        series: [{
            name: 'PPM Level',
            type: 'column',
            data: [11, 23, 22, 27, 13, 22, 37, 21, 44, 22, 30,40]
        }, {
            name: 'Target Level',
            type: 'line',
            data: [20, 25, 36, 30, 45, 35, 40, 45, 50, 36, 39,40]
        }],
        options: {
            // grid: {
            //     show: false
            // },
            colors: ['#598d00'],
          
            chart: {

                height: 350,
                type: 'line',
                stacked: false,
                toolbar: {
                    show: false,
                },
                
            },
            stroke: {
                width: [0, 2, 5],
                curve: 'smooth',
                colors: ['#425459'],
            },
            plotOptions: {
                bar: {
                    columnWidth: '10%',

                },
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'right',
                itemMargin: {
                    horizontal: 10,
                    vertical: 10
                },

            },
            // labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL',
            //     'AUG', 'SEP', 'OCT', 'DEC'
            // ],
            markers: {
                size: 0
            },
            xaxis: {
                categories: whichTypeofCategory
                // labels: {
                //     datetimeFormatter: {
                //         year: 'yyyy',
                //         month: 'MMM \'yy',
                //         day: 'dd MMM',
                //         hour: 'HH:mm'
                //     }
                // }
            },
            yaxis: {
                show: true,
                // min: 20,
                // max: 100
            },
            tooltip: {
                shared: true,
                intersect: false,
            }
        },


    };
    const state = {

        series: [{
            // name: 'Network',
            data: [{
                x: 'Dec 23 2017',
                y: 22
            },
            {
                x: 'Dec 24 2017',
                y: 44
            },

            {
                x: 'Dec 28 2017',
                y: 32
            },
            {
                x: 'Dec 29 2017',
                y: 55
            },
            {
                x: 'Dec 30 2017',
                y: 51
            },
            {
                x: 'Dec 31 2017',
                y: 67
            },
            {
                x: 'Jan 01 2018',
                y: 22
            },


            ],

        }],

        options: {
           
            grid: {
                show: false,
                 padding: {
                        bottom: -45
                }
                
            },
           
            colors: ['#598d00'],
            chart: {
                type: 'area',
                height: 350,
                animations: {
                    enabled: false
                },

                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'straight',

            },
            markers: {
                size: 4,
                hover: {
                    size: 8
                },

            },
            tooltip: {
                intersect: true,
                shared: false
            },

            xaxis: {
                type: "datetime",
                labels: {
                    show: false
                },
                style:{ padding:0 }

            },
            yaxis: [
                { show: false, }

            ],
            stroke: {
                width: 1.5
            },
            legend: {
                show: false
            },
            tooltip: {
                shared: true,
                x: {
                    format: "dd MMM - HH : mm "
                }
            }

        },
    };
    return (
        <React.Fragment>
            {/* <Container fluid> */}
            <Header />
            <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-start my-3 mx-3">
                    <i style={{ backgroundColor: '#f7f7f5', height: 25, cursor: 'pointer' }} onClick={() => setYearCounter(yearCounter - 1)}><IoIosArrowBack /></i>
                    <input
                        type='text'
                        // placeholder="2021"
                        value={yearCounter}
                        style={{
                            height: 25,
                            width: '20%',
                            backgroundColor: '#f7f7f5',
                            border: 'none',
                        }}
                    />
                    <i style={{ backgroundColor: '#f7f7f5', height: 25, cursor:'pointer' }} onClick={() => setYearCounter(yearCounter+1)}> <IoIosArrowForward /></i>
                </div>
                <div className="my-3">
                    <NavLink to="/usage/day" className=' px-3 py-1'
                          onClick={()=> {
                              setIsShowDay(true)
                              setIsShowWeek(false)
                              setIsShowMonth(false)
                              setIsShowYear(false)
                        }}
                        style={{
                            
                            color: isShowDay === true ? '#fff' : '#7F7F7F',
                            backgroundColor: isShowDay === true ? '#598D00' : '',
                            textDecoration: 'none',
                            borderRadius: 5,
                            fontSize:13,
                            fontWeight:500
                            
                        }} >
                        Day
                    </NavLink>
                    <NavLink to="/usage/week" className=' px-3 py-1'
                        onClick={() => {
                            setIsShowDay(false)
                            setIsShowWeek(true)
                            setIsShowMonth(false)
                            setIsShowYear(false)
                        }}
                        style={{
                            
                            color: isShowWeek === true ? '#fff' : '#7F7F7F',
                            backgroundColor: isShowWeek === true ? '#598D00' : '',
                            textDecoration: 'none',
                            borderRadius: 5,
                            fontSize:13,
                            fontWeight:500
                        }} >
                        Week
                    </NavLink>
                    <NavLink to="/usage/month" className=' px-3 py-1'
                        onClick={() => {
                            setIsShowDay(false)
                            setIsShowWeek(false)
                            setIsShowMonth(true)
                            setIsShowYear(false)
                        }}
                        style={{
                            color: isShowMonth === true ? '#fff' : '#7F7F7F',
                            backgroundColor: isShowMonth === true ? '#598D00' : '',
                            textDecoration: 'none',
                            borderRadius: 5,
                            fontSize:13,
                            fontWeight:500
                        }} >
                        Month
                    </NavLink>
                    <NavLink to="/usage/year" className=' px-3 py-1'
                        onClick={() => {
                            setIsShowDay(false)
                            setIsShowWeek(false)
                            setIsShowMonth(false)
                            setIsShowYear(true)
                        }}
                        style={{
                            color: isShowYear === true ? '#fff' : '#7F7F7F',
                            backgroundColor: isShowYear=== true ? '#598D00' : '',
                            textDecoration: 'none',
                            borderRadius: 5,
                            fontSize:13,
                            fontWeight:500
                        }} >
                        Year
                    </NavLink>
                </div>
                <div className="my-3  input-wrapper  " >
                    <input
                        className="col-12 px-3 px-5"
                        onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                        onBlur={(e) => e.target.style.outlineColor = ''}
                        style={{
                            height: 25,
                            backgroundColor: '#f7f7f5',
                            borderRadius: 10,
                            border: 'none',
                            paddingLeft: 10,

                        }}
                        type="search"
                        placeholder="Search..."
                    />
                    <div style={{ marginTop: -25, }} className="  px-3">
                        <IoIosSearch size={20} color={'#838e91'} />
                    </div>
                </div>
            </div>
            <div className="row mt-1">

                <div className="col-12 " style={{ backgroundColor: '#f7f7f5' }}>
                    <ReactApexChart options={mixed.options} series={mixed.series} type="line" height={350} />

                </div>
                <div className="d-flex justify-content-end " style={{ backgroundColor: '#f7f7f5',}}>
                    <Items currentItems={currentItems} />
                    <ReactPaginate
                        containerClassName="pagination pagination-sm"
                        nextLabel=" >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={2}
                        previousLabel="< "
                        renderOnZeroPageCount={null}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName={'active'} /* as this work same as bootstrap class */

                    />
                </div>
            </div>
            <div>
                <div>
                    <div className="d-flex justify-content-between">
                        <div className="mt-2 mx-2"  >
                            <p style={{fontWeight:500}}>NUTRIENT LEVELS</p>

                        </div>
                        <div className="d-flex justify-content-end mt-2">
                            <p style={{ color: '#3d4f54', marginRight: 10, fontWeight: 500, fontSize:14 }} >Add NUTRIENTS</p>
                            <i style={{ marginTop:-3, marginRight:3 }}>
                                <BsPlusCircleFill color="#3d4f54" />
                            </i>
                        </div>

                    </div>

                </div>
                <div className="row">
                    <div style={{ backgroundColor: '#f7f7f5', height: 205, padding: 0, borderRadius: 5, }} className="col-md-2 col-lg-2 mx-3 "
                    >
                        <div className="d-flex justify-content-around">
                            <p style={{ fontSize: 12, fontWeight: 500, color:'#7F7F7F' }}>Nitrate (NO<sub>3</sub>)</p>
                            <p style={{ fontSize: 12, color: '#7F7F7F' }}>150 ppm</p>
                        </div>
                        <div >
                            <ReactApexChart options={state.options} series={state.series} type="area" height={165} />

                        </div>
                    </div>
                    <div style={{ backgroundColor: '#f7f7f5', height: 205, padding: 0, borderRadius: 5, borderWidth: 2 }} className="col-md-2 col-lg-2 mx-3  border border-success border border-2">
                        <div className="d-flex justify-content-around">
                            <p style={{ fontSize: 12, fontWeight: 500, color: '#7F7F7F' }}>Potassium (K)</p>
                            <p style={{ fontSize: 12, color:'#7F7F7F' }}>150 ppm</p>
                        </div>
                        <div >
                            <ReactApexChart options={state.options} series={state.series} type="area" height={165} />

                        </div>
                    </div>
                    <div style={{ backgroundColor: '#f7f7f5', height: 205, padding: 0, borderRadius: 5, }} className="col-md-2 col-lg-2 mx-3 ">
                        <div className="d-flex justify-content-around">
                            <p style={{ fontSize: 12, fontWeight: 500, color: '#7F7F7F' }}>Phosphate (PO<sub>3</sub>)</p>
                            <p style={{ fontSize: 12, color: '#7F7F7F' }}>150 ppm</p>
                        </div>
                        <div>
                            <ReactApexChart options={state.options} series={state.series} type="area" height={165} />

                        </div>
                    </div>
                    <div style={{ backgroundColor: '#f7f7f5', height: 205, padding: 0, borderRadius: 5, }} className="col-md-2 col-lg-2 mx-3 ">
                        <div className="d-flex justify-content-around">
                            <p style={{ fontSize: 12, fontWeight: 500, color: '#7F7F7F' }}>Iron (Fe)</p>
                            <p style={{ fontSize: 12, color: '#7F7F7F' }}>150 ppm</p>
                        </div>
                        <div>
                            <ReactApexChart options={state.options} series={state.series} type="area" height={165} />

                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div style={{ backgroundColor: '#f7f7f5', height: 205, padding: 0,borderRadius:5,  }} className="col-md-2 col-lg-2 mx-3 ">
                        <div className="d-flex justify-content-around">
                            <p style={{ fontSize: 12, fontWeight: 500, color: '#7F7F7F' }}>Ammonium (NH<sub>3</sub>)</p>
                            <p style={{ fontSize: 12, color: '#7F7F7F' }}>150 ppm</p>
                        </div>
                        <div>
                            <ReactApexChart options={state.options} series={state.series} type="area" height={165} />

                        </div>
                    </div>
                    <div style={{ backgroundColor: '#f7f7f5', height: 205, padding: 0,borderRadius:5, }} className="col-md-2 col-lg-2 mx-3 ">
                        <div className="d-flex justify-content-around">
                            <p style={{ fontSize: 12, fontWeight: 500, color: '#7F7F7F' }}>Calcium (Ca)</p>
                            <p style={{ fontSize: 12, color: '#7F7F7F' }}>150 ppm</p>
                        </div>
                        <div>
                            <ReactApexChart options={state.options} series={state.series} type="area" height={165} />

                        </div>
                    </div>
                    <div style={{ backgroundColor: '#f7f7f5', height: 205, padding: 0,borderRadius:5, }} className="col-md-2 col-lg-2 mx-3 ">
                        <div className="d-flex justify-content-around">
                            <p style={{ fontSize: 12, fontWeight: 500, color: '#7F7F7F' }}>Hydrogen </p>
                            <p style={{ fontSize: 12, color: '#7F7F7F' }}>150 ppm</p>
                        </div>
                        <div>
                            <ReactApexChart options={state.options} series={state.series} type="area" height={165} />

                        </div>
                    </div>
                    <div style={{ backgroundColor: '#f7f7f5', height: 205, padding: 0,borderRadius:5, }} className="col-md-2 col-lg-2 mx-3 ">
                        <div className="d-flex justify-content-around">
                            <p style={{ fontSize: 12, fontWeight: 500, color: '#7F7F7F' }}>Peroxide (H<sub>2</sub>O<sub>2</sub>)</p>
                            <p style={{ fontSize: 12, color: '#7F7F7F' }}>150 ppm</p>
                        </div>
                        <div>
                            <ReactApexChart options={state.options} series={state.series} type="area" height={165} />

                        </div>
                    </div>
                </div>
            </div>

            {/* </Container> */}
        </React.Fragment>
    )
}
export default UsageStepsCompleted

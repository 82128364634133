import React, { useEffect } from "react";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import './iconStyle.css'
import Logo from '../assets/Logo.png'
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaLock } from 'react-icons/fa'
const SideBar = () => {
    const location = useLocation();
    const navigate = useNavigate()
   
useEffect(() => {
    if (location.pathname === '/') {
        navigate('/eqm')
    }
}, [location]);
   
    return (
        // <div style={{ backgroundColor: '#3D4F54' }}
        //     className="min-vh-100 "
        // >
        //     <div className="mx-4" >
        //         <img src={Logo}
        //             width="30%" height="30%"
        //             className='mx-5 mt-2'
        //         />
        //         <p style={{ color: "white", fontSize: 13, fontFamily:'sans-serif' }} className='mt-2 mx-4' >DISTRICT FARMS</p>
        //     </div>
        //  <div >

        //         <NavLink to="/dashboard" style={{ color: location.pathname === "/dashboard" ? '#bed800' : 'white', textDecoration: 'none', display: 'inline-block', marginTop: 30}}  > <span className="icon-EQM-Dashboard "></span> Dashboard</NavLink><br/>
        //         <NavLink to="/grower" style={{ color: location.pathname === "/grower" ? '#bed800' : 'white', textDecoration: 'none', display: 'inline-block', marginTop: 20}} ><span className="icon-EQM-Person "></span>  Grower</NavLink><br />
        //         <NavLink to="/seeding" style={{ color: location.pathname === "/seeding" ? '#bed800' : 'white', textDecoration: 'none', display: 'inline-block', marginTop: 20}} > <span className="icon-EQM-Seeding "></span> Seeding</NavLink><br />
        //         <NavLink to="/transplanting" style={{ color: location.pathname === "/transplanting" ? '#bed800' : 'white', textDecoration: 'none', display: 'inline-block', marginTop: 20}}> <span className="icon-EQM-Transplating "></span> Transplanting</NavLink><br />
        //         <NavLink to="/harvesting" style={{ color: location.pathname === "/harvesting" ? '#bed800' : 'white', textDecoration: 'none', display: 'inline-block', marginTop: 20}}> <span className="icon-EQM-Harvesting "></span> Harvesting</NavLink><br />
        //         <NavLink to="/tanks" style={{ color: location.pathname === "/tanks" ? '#bed800' : 'white', textDecoration: 'none', display: 'inline-block', marginTop: 20}}> <span className="icon-EQM-Tasks "></span>Tanks</NavLink><br />
        //         <NavLink to="/compliance" style={{ color: location.pathname === "/compliance" ? '#bed800' : 'white', textDecoration: 'none', display: 'inline-block', marginTop: 20 }}><span className="icon-EQM-Complians "></span> Compliance</NavLink><br />
        //         <NavLink to="/logistics" style={{ color: location.pathname === "/logistics" ? '#bed800' : 'white', textDecoration: 'none', display: 'inline-block', marginTop: 20 }}> <span className="icon-EQM-Logistics "></span>Logistics</NavLink><br />
        //         <NavLink to="/sale" style={{ color: location.pathname === "/sale" ? '#bed800' : 'white', textDecoration: 'none', display: 'inline-block', marginTop: 20 }}> <span className="icon-EQM-Sale "></span>Sale</NavLink><br />
        //         <NavLink to="/eqm" style={{ color: location.pathname === "/eqm" ? '#bed800' : 'white', textDecoration: 'none', display: 'inline-block', marginTop: 20 }}> <span className="icon-EQM "></span>EQM <span class="icon-EQM-Lock mx-4"></span></NavLink><br />
        //         <NavLink to="/dashboard" style={{ color: location.pathname === "/eqm" ? '#bed800' : 'white', textDecoration: 'none', display: 'inline-block', marginTop: 250, marginBottom:20 }}> <span className="icon-EQM-Setting "></span>Settings</NavLink><br />
        //  </div>
        <>
            <div className=" d-none d-xs-none d-sm-none d-md-none d-lg-block " >

                <ProSidebar >
                    <SidebarHeader style={{ backgroundColor: '#3D4F54' }}>
                        <div className="mx-4" >
                            <img src={Logo}
                                width="20%" height="20%"
                                className='mx-4 mt-2'
                            />
                            <p style={{ color: "white", fontSize: 10 }} className='mt-2 mx-2' >DISTRICT FARMS</p>
                        </div>
                    </SidebarHeader>
                    <SidebarContent style={{ backgroundColor: '#3D4F54',  }} >
                        <Menu style={{ marginLeft: -15 }}>

                            <MenuItem
                                icon={location.pathname === "/dashboard" ? <span className="icon-EQM-DashboardOnClick "></span> : <span className="icon-EQM-Dashboard "></span>}
                                style={{ color: location.pathname === "/dashboard" ? '#bed800' : 'white', fontSize: 13, fontFamily: 'revert' }} >Dashboard  <NavLink to="/dashboard" /></MenuItem>
                            <MenuItem
                                icon={location.pathname === "/grower" ? <span className="icon-EQM-PersonOnClick "></span> : <span className="icon-EQM-Person "></span>}
                                style={{ color: location.pathname === "/grower" ? '#bed800' : 'white', fontSize: 13, fontFamily: 'revert' }}>  Grower <NavLink to="/grower" /></MenuItem>
                            <MenuItem
                                icon={location.pathname === "/seeding" ? <span className="icon-EQM-SeedingOnClick "></span> : <span className="icon-EQM-Seeding "></span>
                                }
                                style={{ color: location.pathname === "/seeding" ? '#bed800' : 'white', fontSize: 13, fontFamily: 'revert' }}>Seeding <NavLink to="/seeding" /></MenuItem>
                            <MenuItem
                                icon={location.pathname === "/transplanting" ? <span className="icon-EQM-TransplatingOnClick "></span> : <span className="icon-EQM-Transplating "></span>
                                }
                                style={{ color: location.pathname === "/transplanting" ? '#bed800' : 'white', fontSize: 13, fontFamily: 'revert' }}>Transplanting <NavLink to="/transplanting" /></MenuItem>
                            <MenuItem
                                icon={location.pathname === "/harvesting" ? <span className="icon-EQM-HarvestingOnClick "></span> : <span className="icon-EQM-Harvesting "></span>
                                }
                                style={{ color: location.pathname === "/harvesting" ? '#bed800' : 'white', fontSize: 13, fontFamily: 'revert' }}>Harvesting <NavLink to="/harvesting" /></MenuItem>
                            <MenuItem
                                icon={location.pathname === "/tanks" ? <span className="icon-EQM-TasksOnClick "></span> : <span className="icon-EQM-Tasks "></span>
                                }
                                style={{ color: location.pathname === "/tanks" ? '#bed800' : 'white', fontSize: 13, fontFamily: 'revert' }}>Tanks <NavLink to="/tanks" /></MenuItem>
                            <MenuItem
                                icon={location.pathname === "/compliance" ? <span className="icon-EQM-CompliansOnClick "></span> : <span className="icon-EQM-Complians "></span>
                                }
                                style={{ color: location.pathname === "/compliance" ? '#bed800' : 'white', fontSize: 13, fontFamily: 'revert' }}>Compliance <NavLink to="/compliance" /></MenuItem>
                            <MenuItem
                                icon={location.pathname === "/logistics" ? <span className="icon-EQM-LogisticsOnClick "></span> : <span className="icon-EQM-Logistics "></span>
                                }
                                style={{ color: location.pathname === "/logistics" ? '#bed800' : 'white', fontSize: 13, fontFamily: 'revert' }}>Logistics <NavLink to="/logistics" /></MenuItem>
                            <MenuItem
                                icon={location.pathname === "/sale" ? <span className="icon-EQM-SaleOnClick "></span> : <span className="icon-EQM-Sale "></span>
                                }
                                style={{ color: location.pathname === "/sale" ? '#bed800' : 'white', fontSize: 13, fontFamily: 'revert' }}>Sale <NavLink to="/sale" /></MenuItem>
                            <MenuItem
                                icon={location.pathname === "/eqm" || location.pathname === "/eqm/steps" || location.pathname === "/" ? <span className="icon-EQMOnClick "></span> : <span className="icon-EQM "></span>
                                }
                                style={{ color: location.pathname === "/eqm" || location.pathname === "/eqm/steps" || location.pathname === "/" ? '#bed800' : 'white', fontSize: 13, fontFamily: 'revert' }}>EQM<NavLink to="/eqm" />
                                {location.pathname === "/eqm" || location.pathname === "/eqm/steps" || location.pathname === "/" ? <i style={{ marginLeft: 20 }}><FaLock color="#bed800" /> </i> : <i style={{ marginLeft: 20 }}><FaLock color="#fff" /> </i>}

                            </MenuItem>

                        </Menu>

                    </SidebarContent>
                    <SidebarFooter style={{ backgroundColor: '#3D4F54' }}>
                        <Menu>
                            <MenuItem
                                icon={location.pathname === "/settings" ? <span className="icon-EQM-SettingOnClick "></span> : <span className="icon-EQM-Setting "></span>
                                }
                                style={{ color: location.pathname === "/settings" ? '#bed800' : 'white', fontSize: 13, fontFamily: 'revert', marginTop: 200 }}   > Settings<NavLink to="/settings" /> </MenuItem>

                        </Menu>
                    </SidebarFooter>
                </ProSidebar>

            </div>
            <div className=" d-lg-none d-sm-block d-md-block d-xs-block " >

                <ProSidebar  collapsed={true} collapsedWidth={500}>
                    <SidebarContent style={{ backgroundColor: '#3D4F54' }} >
                        <Menu>

                            <MenuItem>{location.pathname === "/dashboard" ? <span className="icon-EQM-DashboardOnClick "></span> : <span className="icon-EQM-Dashboard "></span>} <NavLink to="/dashboard" /></MenuItem>
                            <MenuItem>  {location.pathname === "/grower" ? <span className="icon-EQM-PersonOnClick "></span> : <span className="icon-EQM-Person "></span>} <NavLink to="/grower" /></MenuItem>
                            <MenuItem>{location.pathname === "/seeding" ? <span className="icon-EQM-SeedingOnClick "></span> : <span className="icon-EQM-Seeding "></span>} <NavLink to="/seeding" /></MenuItem>
                            <MenuItem>{location.pathname === "/transplanting" ? <span className="icon-EQM-TransplatingOnClick "></span> : <span className="icon-EQM-Transplating "></span>} <NavLink to="/transplanting" /></MenuItem>
                            <MenuItem>{location.pathname === "/harvesting" ? <span className="icon-EQM-HarvestingOnClick "></span> : <span className="icon-EQM-Harvesting "></span>} <NavLink to="/harvesting" /></MenuItem>
                            <MenuItem>{location.pathname === "/tanks" ? <span className="icon-EQM-TasksOnClick "></span> : <span className="icon-EQM-Tasks "></span>} <NavLink to="/tanks" /></MenuItem>
                            <MenuItem>{location.pathname === "/compliance" ? <span className="icon-EQM-CompliansOnClick "></span> : <span className="icon-EQM-Complians "></span>} <NavLink to="/compliance" /></MenuItem>
                            <MenuItem>{location.pathname === "/logistics" ? <span className="icon-EQM-LogisticsOnClick "></span> : <span className="icon-EQM-Logistics "></span>} <NavLink to="/logistics" /></MenuItem>
                            <MenuItem>{location.pathname === "/sale" ? <span className="icon-EQM-SaleOnClick "></span> : <span className="icon-EQM-Sale "></span>} <NavLink to="/sale" /></MenuItem>
                            <MenuItem>{location.pathname === "/eqm" ? <span className="icon-EQMOnClick "></span> : <span className="icon-EQM "></span>}<NavLink to="/eqm" />
                                {location.pathname === "/eqm" ? <i style={{ marginLeft: 20 }}><FaLock color="#bed800" /> </i> : <i style={{ marginLeft: 20 }}><FaLock color="#fff" /> </i>}</MenuItem>

                        </Menu>

                    </SidebarContent>
                    <SidebarFooter style={{ backgroundColor: '#3D4F54' }}>
                        <Menu>
                            <MenuItem style={{ marginTop: 200 }}   >{location.pathname === "/settings" ? <span className="icon-EQM-SettingOnClick "></span> : <span className="icon-EQM-Setting "></span>}<NavLink to="/settings" /> </MenuItem>

                        </Menu>
                    </SidebarFooter>
                </ProSidebar>

            </div>

        </>

    );

}
export default SideBar

const styles = {
    sidebar: {
        textDecoration: "none",
       

    },
   

};
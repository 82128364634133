import React, { useState } from "react";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box'
import { useNavigate } from "react-router-dom";
import {
    Form,
    Col,
    Row,
    Button,
} from 'reactstrap'

import TankSetup from "../WizardForms/TankSetup";
import Nutrients from "../WizardForms/Nutrients";
import PPMLevels from "../WizardForms/PPMLevels";
import Plants from "../WizardForms/Plants";
import Success from "../WizardForms/Success";

function getStepContent(step) {
    console.log('sjhsvx', step)
    switch (step) {
        case 0:
            return <TankSetup />;

        case 1:
            return <Nutrients />;
        case 2:
            return <PPMLevels />;
        case 3:
            return <Plants />;
        case 4:
            return <Success />;
        default:
            return 'close';
        // navigate('/EQM/usage/main')
    }
}

const UsageSteps = () => {

    const navigate = useNavigate()

    const [activeState, setActiveState] = useState(0)
    const [completedState, setCompletedState] = useState(0)
    const steps = [
        '',
        '',
        '',
        ''
    ];
    return (
        <div>
            <div>
                <div className="d-flex justify-content-between m-2">
                    <p >EQM</p>
                    <span style={{ fontSize: 12, marginRight: 488 }}>Frederick, MD<i className="icon-EQM-Send mx-2 "></i></span>
                </div>
            </div>
            <div>
                {activeState == 5 ? navigate('/overview') : (
                    <Form >

                        <Row className='my-3 d-flex justify-content-center'>
                            <div 
                                style={{ backgroundColor: '#f7f7f5', borderRadius: 10, paddingTop:70, paddingBottom:30 }}
                            >
                                <div className=' d-flex justify-content-center '>
                                    <h3 style={{ marginTop: -53, fontSize: 20, fontWeight: 'bold' }}  >Welcome to EQM</h3>

                                </div>

                                <div className='mt-4 d-flex justify-content-center'>
                                    <Box sx={{ width: 355, }}>
                                        <Stepper activeStep={activeState} orientation="horizontal"
                                        >
                                            {steps.map((label) => (
                                                <Step key={label}
                                                    sx={{
                                                        '& .MuiStepLabel-root .Mui-completed': {
                                                            color: '#598D00', // circle color (COMPLETED)
                                                            fontSize: 35,
                                                        },
                                                        '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                                        {
                                                            color: 'common.white', // Just text label (COMPLETED)
                                                        },
                                                        '& .MuiStepLabel-root .Mui-active': {
                                                            color: 'black', // circle color (ACTIVE)
                                                            fontSize: 35

                                                        },
                                                        '& .Mui-disabled .MuiStepIcon-root': {
                                                            fontSize: 35,
                                                            color: '#e6e6e6',
                                                            text: {
                                                                fill: "#b3b3b3",
                                                            },

                                                        },
                                                        '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                                        {
                                                            color: 'red', // Just text label (ACTIVE)

                                                        },
                                                        '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                                            fill: 'common.white', // circle's number (ACTIVE)

                                                        },
                                                    }}
                                                >
                                                    <StepLabel
                                                    >{label}</StepLabel>

                                                </Step>
                                            ))}
                                        </Stepper>
                                    </Box>
                                </div>

                            </div>

                            <Col xs={6}>

                                {getStepContent(activeState)}
                                {activeState >= 1 && activeState <= 3 &&
                                    <div className="d-flex justify-content-center "
                                        style={{ marginTop: '20%' }}
                                    >
                                        <div className="m-2">
                                            <Button
                                                style={{ backgroundColor: 'white', outlineColor: '#598D00', color: '#598D00', fontSize: 13, padding:'6px 32px 6px 32px' }}
                                                type="button"
                                                onClick={() => setActiveState(activeState >= 0 ? activeState - 1 : 0)}
                                               
                                            >

                                                Back
                                            </Button>
                                        </div>

                                        <div className="m-2">
                                            <Button
                                                style={{ backgroundColor: '#598D00', fontSize: 13, padding: '6px 32px 6px 32px' }}
                                                type="button"
                                                onClick={() => setActiveState(activeState < 5 ? activeState + 1 : 3)}
                                               
                                            >
                                                {activeState == '3' ?
                                                    'Configure' : 'Next'
                                                }
                                                
                                            </Button>
                                        </div>


                                    </div>
                                }
                                {activeState == '0' || activeState == '4' ?

                                    (<div className="d-flex justify-content-center">
                                        <Button
                                            style={{ backgroundColor: '#598D00', marginTop: '20%', fontSize:13 }}
                                            type="button"
                                            onClick={() => { setActiveState(activeState + 1) }}
                                            className='px-4 py-2'
                                        >
                                            {activeState == '0' ?
                                                'Continue' : 'Close Window'
                                            }
                                        </Button>

                                    </div>) : null
                                }




                            </Col>
                        </Row>

                    </Form>
                )
                }
            </div >
        </div>
       
    )
}
export default UsageSteps
import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Label, Button } from "reactstrap";
import SimpleBar from "simplebar-react";

import './iconStyle.css'
import { NavLink, useLocation, Link } from "react-router-dom";
import { HiPencilAlt } from 'react-icons/hi'
import 'react-pro-sidebar/dist/css/styles.css';
import { BsPlusCircleFill } from 'react-icons/bs';
import { IoIosClose } from 'react-icons/io';
import './rightSidebarStyle.css'
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent, SubMenu, } from 'react-pro-sidebar';
import Select, { StylesConfig } from 'react-select';
import { Transition } from 'react-transition-group';
const tankOptions = [
    { label: 'Option', value: '1' },
    { label: 'Option', value: '2' },
    { label: 'Option', value: '3' },
    { label: 'Option', value: '4' }

]
const Header = () => {
    const location = useLocation();
    const [isOpenNotification, setIsOpenNotification] = useState(false)
    const [isOpenProfile, setIsOpenProfile] = useState(false)
    const [isOpenSettings, setIsOpenSettings] = useState(false)
    const [isAddTank, setIsAddTank] = useState(false)
    const [IsAddNutrient, setIsAddNutrient] = useState(false)
    const [selectedtank, setSelectedtank] = useState('')
    const [tankName, setTankName] = useState('')

    const [tankData, setTankData] = useState([
        { 'name': 'TANK A', 'nutrient': 'NITRATE' },
        { 'name': 'TANK B', 'nutrient': 'POTASSIUM' },
        { 'name': 'TANK C', 'nutrient': 'CALCIUM' },
        { 'name': 'TANK D', 'nutrient': 'IRON' },
    ])
    const [nutrientName, setNutrientName] = useState('')
    const [nutrientsData, setNutrientsData] = useState([
        { 'name': 'NITRATE', },
        { 'name': 'POTASSIUM', },
        { 'name': 'CALCIUM', },
        { 'name': 'IRON', },
    ])
    const handletank = async (e) => {
        await setSelectedtank(e);
    }
    const addNutrientOptions = async () => {
        if (nutrientName !== '' &&
            nutrientName !== 'undefined'

        ) {
            setIsAddNutrient(false)
            let list = [...nutrientsData, { 'name': nutrientName, }]
            setNutrientsData(list)

        }
        else {
            console.log('sorrrrry')
        }
    }
    const addTankOptions = async () => {
        if (tankName !== '' &&
            tankName !== 'undefined' &&
            selectedtank !== '' &&
            selectedtank !== 'undefined'

        ) {
            setIsAddTank(false)
            let list = [...tankData, { 'name': tankName, 'nutrient': selectedtank.label }]
            setTankData(list)

        }
        else {
            console.log('sorrrrry')
        }
    }
    const setRightSidebarState = async () => {
        await setIsOpenProfile(true)
        await setIsOpenSettings(!isOpenSettings)
        // setIsOpenProfile(false)


    }
    const duration = 100;

    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0,
    }

    const transitionStyles = {
        entering: { opacity: 1 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },

    };
    return (
        <React.Fragment>

            <div style={{ marginLeft: -10, marginRight: -10 }}>
                <div >
                    <nav className="navbar navbar-light ">
                        <div className="container-fluid">
                            <a className="navbar-brand">EQM</a>
                            <span style={{ fontSize: 12 }}>Frederick, MD<i className="icon-EQM-Send mx-2 "></i></span>

                            <div className="d-flex ">
                                <i className="mx-2 ">
                                    <sub >
                                        <span style={{ borderRadius: 10, backgroundColor: 'red', color: 'white', }} className="px-1">2</span>
                                    </sub>
                                    <i
                                        className="icon-EQM-Bell mt-1"
                                        style={{ cursor: 'pointer', }}
                                        onClick={() => setIsOpenNotification(true)}
                                    ></i>

                                    {/* <Transition in={isOpenNotification} timeout={duration}>
                                        {state => (
                                            <div style={{
                                                ...defaultStyle,
                                                ...transitionStyles[state]
                                            }}>
                                                <Dropdown style={{ position: 'absolute', marginTop: 15, right: 465, }} isOpen={isOpenNotification} toggle={() => setIsOpenNotification(!isOpenNotification)}  >
                                                    <DropdownMenu className='p-0'
                                                        style={{ width: 350, transition: 'height 200ms' }}
                                                    >
                                                        <SimpleBar style={{ maxHeight: "230px" }}>
                                                            <div className="p-3">
                                                                <Row className="align-items-center">
                                                                    <Col >
                                                                        <h6 className="m-0"> Notifications </h6>
                                                                    </Col>
                                                                    <div className="col-auto">
                                                                        <Link to="#" className="small" style={{ color: 'black', textDecoration: 'none' }}><b>View All</b></Link>
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                            <div className="my-3">
                                                                <Link to="#" style={{ textDecoration: 'none', minWidth: '100%' }}>
                                                                    <div className="d-flex">
                                                                        <img src='https://loveshayariimages.in/wp-content/uploads/2021/06/Cute-Girl-Pic-For-Dp-Photo-Free-Hd.jpg'
                                                                            width="65" height="65" style={{ borderRadius: 60, marginLeft: 10 }}
                                                                            alt="user-pic" />
                                                                        <div className="flex-1 px-3">
                                                                            <h6 className="mx-1 my-1 mb-1" style={{ color: '#598d00' }}>Fayyaz</h6>
                                                                            <div className="font-size-12 text-muted">
                                                                                <p className="mx-1 my-1">It will seem like simplified .</p>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            <hr />
                                                            <div className="my-3">
                                                                <Link to="#" style={{ textDecoration: 'none', minWidth: '100%' }}>
                                                                    <div className="d-flex">
                                                                        <img src='https://1.bp.blogspot.com/-O42XFapJyGk/YQTR_hof3EI/AAAAAAAAERk/bw4-VfYwCGQbvyG9lxVgB7KDYx3Ul0XowCLcBGAsYHQ/s631/hurt%2Bdp%2Bpic.jpeg'
                                                                            width="65" height="65" style={{ borderRadius: 60, marginLeft: 10 }}
                                                                            alt="user-pic" />
                                                                        <div className="flex-1 px-3">
                                                                            <h6 className="mt-0 mb-1" style={{ color: '#598d00' }}>Taimoor</h6>
                                                                            <div className="font-size-12 text-muted">
                                                                                <p className="mb-1">It will seem like simplified English.</p>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            <hr />
                                                            <div className="my-3">
                                                                <Link to="#" style={{ textDecoration: 'none', minWidth: '100%' }}>
                                                                    <div className="d-flex">
                                                                        <img src='https://cdn.sharechat.com/picphotoletesphoto.photos..babypic..nicepic.butifullpic_24aa3541_1597288102394_cmprsd_40.jpg'
                                                                            width="65" height="65" style={{ borderRadius: 60, marginLeft: 10 }}
                                                                            alt="user-pic" />
                                                                        <div className="flex-1 px-3">
                                                                            <h6 className="mt-0 mb-1" style={{ color: '#598d00' }}>Rizwan</h6>
                                                                            <div className="font-size-12 text-muted">
                                                                                <p className="mb-1">It will seem like simplified English.</p>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            <hr />
                                                            <div className="my-3">
                                                                <Link to="#" style={{ textDecoration: 'none', minWidth: '100%' }}>
                                                                    <div className="d-flex">
                                                                        <img src='https://www.whatsappprofiledpimages.com/wp-content/uploads/2019/01/Profile-Pic-Images-5-300x286.jpg'
                                                                            width="65" height="65" style={{ borderRadius: 60, marginLeft: 10 }}
                                                                            alt="user-pic" />
                                                                        <div className="flex-1 px-3">
                                                                            <h6 className="mt-0 mb-1" style={{ color: '#598d00' }}>Asif</h6>
                                                                            <div className="font-size-12 text-muted">
                                                                                <p className="mb-1">It will seem like simplified English.</p>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </SimpleBar>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                        )}
                                    </Transition> */}

                                </i>
                                <p className="my-1 " style={{ fontSize: 12 }} >John Smith</p>
                                <i
                                    className="icon-EQM-Profile mx-2 mt-1"
                                    style={{ cursor: 'pointer' }}
                                // onClick={() => setIsOpenProfile(true)}
                                >
                                    {/* <Dropdown style={{ position: 'absolute', marginTop: 20, right: 201 }} isOpen={isOpenProfile} toggle={() => setIsOpenProfile(!isOpenProfile)}  >
                                        <DropdownMenu className='p-0'
                                            style={{ width: 50 }}
                                        > <SimpleBar style={{ maxHeight: "230px" }}>

                                                <div className="my-2 d-flex justify-content-center">
                                                    <Link to="#" style={{ textDecoration: 'none',  color: '#598d00' }}>
                                                        Profile
                                                    </Link>

                                                </div>

                                                <hr />
                                                <div className="my-2 d-flex justify-content-center">
                                                    <Link to="#" style={{ textDecoration: 'none', color: '#598d00' }}>
                                                        Logout
                                                    </Link>
                                                </div>

                                            </SimpleBar>
                                        </DropdownMenu>
                                    </Dropdown> */}

                                </i>
                                <i className="icon-EQM-Menu mx-2 mt-1" style={{ cursor: 'pointer' }}></i>
                                <i
                                    className="icon-EQM-Dots mx-2 mt-1"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setIsOpenSettings(true)}
                                >
                                    <div className="position-relative " style={{ overflow: 'hidden', top: -30, right: -14 }}>
                                        <Dropdown style={{ position: 'absolute', top: 0, right: isOpenSettings === true ? 148 : -148, width: 90, transition: 'all 0.5s' }} isOpen={isOpenSettings} toggle={() => setIsOpenSettings(!isOpenSettings)}  >
                                            <DropdownMenu className='p-0' >

                                                <ProSidebar breakPoint='xs' width='19%' style={{ position: 'fixed' }} >
                                                    <SidebarHeader style={{ backgroundColor: '#3D4F54' }}>
                                                        <div className=" d-flex justify-content-start" >
                                                            <p style={{ color: "white", fontSize: 13, fontFamily: 'sans-serif' }} className='mt-2 mx-2' >EQM Settings</p>
                                                            <span style={{ marginLeft: 110 }} >
                                                                <IoIosClose size={30} color={'white'}
                                                                    onClick={setRightSidebarState}
                                                                />
                                                            </span>
                                                        </div>
                                                    </SidebarHeader>
                                                    <SidebarContent style={{ backgroundColor: '#3D4F54' }} >
                                                        <Menu  >
                                                            <SubMenu title="PARAMETERS" style={{ backgroundColor: '#b1b9bb', marginTop: 10, color: '#3f4f56', fontSize: 16, fontFamily: 'sans-serif', fontWeight: 'bold' }}  >
                                                                <div style={{ backgroundColor: '#3d4f54', marginTop: -14, marginBottom: -15, marginLeft: -24 }}>


                                                                    <SubMenu title="TANKS" style={{ color: 'white', fontWeight: 'lighter', fontSize: 13 }} className=" border-bottom "  >

                                                                        {/* <> */}
                                                                        <table style={{ backgroundColor: '#4d5e62', marginLeft: -21, }}  >
                                                                            <div style={{ marginLeft: 10, marginRight: 58 }}>
                                                                                <tr className="row justify-content-between">
                                                                                    <td className=' py-2 col-7 px-4' style={{ color: '#bed800', }}>TANK</td>
                                                                                    <td className=' py-2 col-5 px-4' style={{ color: '#bed800', }}> NUTRIENT</td>
                                                                                </tr>
                                                                                {tankData.map((item, index) => (
                                                                                    <tr className="row justify-content-between">
                                                                                        <td className=' py-2 col-7 px-4 '>{item.name}</td>
                                                                                        <td className=' py-2 col-5 px-4'>{item.nutrient}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </div>


                                                                        </table>
                                                                        <div className="mt-3">
                                                                            <p style={{ color: '#bed800', marginTop: 10 }} >Add new tank</p>
                                                                            <BsPlusCircleFill size={18} style={{ color: '#bed800', marginTop: -60, marginLeft: 196 }} onClick={() => setIsAddTank(true)} />
                                                                        </div>


                                                                        {isAddTank == true &&
                                                                            <>
                                                                                <div style={{ marginLeft: -15, }} >
                                                                                    <Label>Tank Name</Label><br />
                                                                                    <input
                                                                                        style={{ height: 30, width: 80, borderRadius: 5, border: 'white solid', backgroundColor: '#4d5e62', borderWidth: 1, color: "white" }}
                                                                                        className=" px-1 mt-1 "
                                                                                        onFocus={(e) => e.target.style.outlineColor = '#bed800'}
                                                                                        onBlur={(e) => e.target.style.outlineColor = ''}
                                                                                        id="NoTanks"
                                                                                        placeholder="50"
                                                                                        type="text"
                                                                                        onChange={(e) => setTankName(e.target.value)}
                                                                                    />
                                                                                </div>
                                                                                <div style={{ marginTop: -55, marginLeft: 73 }} >
                                                                                    <Label>Nutrient</Label><br />
                                                                                    <div style={{ marginTop: 5, }}>
                                                                                        <Select
                                                                                            styles={colourStyles}
                                                                                            placeholder='Phosphorous'
                                                                                            options={tankOptions}
                                                                                            value={selectedtank}
                                                                                            onChange={handletank}
                                                                                        />
                                                                                    </div>

                                                                                </div>
                                                                            </>

                                                                        } {
                                                                            isAddTank == true &&
                                                                            <div className="mt-4">
                                                                                <Button className="mx-4 text-center py-1" style={{ backgroundColor: '#bed800', fontSize: 12, }} onClick={() => addTankOptions()}>ENTER</Button>
                                                                                <p style={{ fontSize: 12, marginTop: -20, marginLeft: 110 }} onClick={() => setIsAddTank(false)}>CLEAR</p>

                                                                            </div>
                                                                        }


                                                                        {/* </> */}
                                                                    </SubMenu>

                                                                    <SubMenu title="NUTRIENTS" style={{ color: 'white', fontWeight: 'lighter', fontSize: 13 }} className=" border-bottom " >

                                                                        <>
                                                                            <table style={{ backgroundColor: '#4d5e62', marginLeft: -21, }} >
                                                                                <div style={{ marginLeft: 10, marginRight: 76 }}>
                                                                                    {nutrientsData.map((item, index) => (
                                                                                        <tr className="row justify-content-between">
                                                                                            <td className='py-2 col-7  my-2'>{item.name}</td>
                                                                                            <td className='  py-2 col-5  my-2'>
                                                                                                <input
                                                                                                    style={{ width: 70 }}
                                                                                                    onFocus={(e) => e.target.style.outlineColor = '#bed800'}
                                                                                                    onBlur={(e) => e.target.style.outlineColor = ''}
                                                                                                    id="NoTanks"
                                                                                                    placeholder="10"
                                                                                                    type="number"
                                                                                                // onChange={handleText}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </div>
                                                                            </table>
                                                                            <div>
                                                                                <p style={{ color: '#bed800', marginLeft: -15, marginTop: 10 }} >Add NUTRIENT</p>

                                                                                <BsPlusCircleFill size={18} style={{ color: '#bed800', marginTop: -55, marginLeft: 196 }} onClick={() => setIsAddNutrient(true)} />
                                                                            </div>


                                                                            {IsAddNutrient == true &&
                                                                                <div >

                                                                                    <input
                                                                                        style={{ height: 35, borderRadius: 5, border: 'white solid', backgroundColor: '#4d5e62', borderWidth: 1, color: "white" }}
                                                                                        className=" px-3 my-2  "
                                                                                        onFocus={(e) => e.target.style.outlineColor = '#bed800'}
                                                                                        onBlur={(e) => e.target.style.outlineColor = ''}
                                                                                        id="NoTanks"
                                                                                        placeholder="50"
                                                                                        type="text"
                                                                                        onChange={(e) => setNutrientName(e.target.value)}
                                                                                    />
                                                                                </div>
                                                                            } {
                                                                                IsAddNutrient == true &&
                                                                                <div className="mt-4">
                                                                                    <Button className="mx-4 text-center py-1" style={{ backgroundColor: '#bed800', fontSize: 12, }} onClick={() => addNutrientOptions()}>ENTER</Button>
                                                                                    <p style={{ fontSize: 12, marginTop: -20, marginLeft: 110 }} onClick={() => setIsAddNutrient(false)}>CLEAR</p>

                                                                                </div>

                                                                            }
                                                                        </>
                                                                    </SubMenu>

                                                                    <SubMenu title="PUMP DOSING" style={{ color: 'white', fontWeight: 'lighter', fontSize: 13 }} >
                                                                        <MenuItem style={{ color: 'white', }} >Option ONE </MenuItem>
                                                                        <MenuItem style={{ color: 'white', }} >Option TWO</MenuItem>
                                                                        <MenuItem style={{ color: 'white', }} >Option THREE</MenuItem>
                                                                    </SubMenu>
                                                                </div>
                                                            </SubMenu>
                                                            <SubMenu title="PERMISSIONS" style={{ backgroundColor: '#b1b9bb', marginTop: 10, color: '#3f4f56', fontSize: 16, fontFamily: 'sans-serif', fontWeight: 'bold' }} >
                                                                <MenuItem style={{ color: 'white', }}  >Component 1</MenuItem>
                                                                <MenuItem style={{ color: 'white', }} >Component 2</MenuItem>
                                                                <MenuItem style={{ color: 'white', }} >Component 1</MenuItem>
                                                                <MenuItem style={{ color: 'white', }} >Component 2</MenuItem>
                                                            </SubMenu>
                                                            <SubMenu title="PLANT TYPE" style={{ backgroundColor: '#b1b9bb', marginTop: 10, color: '#3f4f56', fontSize: 16, fontFamily: 'sans-serif', fontWeight: 'bold' }} >
                                                                <MenuItem style={{ color: 'white', }} >Component 1</MenuItem>
                                                                <MenuItem style={{ color: 'white', }} >Component 2</MenuItem>
                                                                <MenuItem style={{ color: 'white', }} >Component 1</MenuItem>
                                                                <MenuItem style={{ color: 'white', }} >Component 2</MenuItem>
                                                            </SubMenu>
                                                        </Menu>
                                                    </SidebarContent>
                                                    <SidebarFooter style={{ backgroundColor: '#3D4F54', marginLeft: -25, }}>
                                                        <Menu>
                                                            <MenuItem icon={<HiPencilAlt size={20} />} style={{ color: 'white' }}  >Edit<NavLink to="#" /> </MenuItem>
                                                        </Menu>
                                                    </SidebarFooter>
                                                </ProSidebar>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </i>
                            </div>
                        </div>
                    </nav>
                </div>
                <nav class="navbar navbar-expand-sm navbar-light bg-light">
                    <div class="container-fluid">
                        <a class="navbar-brand" href="#"></a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <NavLink to="/overview" className='mx-2 px-3 py-1'
                                        style={{
                                            color: location.pathname === "/overview" ? '#fff' : 'black',
                                            backgroundColor: location.pathname === "/overview" ? '#598D00' : '',
                                            textDecoration: 'none',
                                            borderRadius: 5,
                                            fontSize: 13
                                        }} >
                                        Overview
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to='/usage/year' className='mx-2 px-3 py-1'
                                        style={{
                                            // || location.pathname === "/usage/steps" || location.pathname === "/eqm/usage/main"
                                            // || location.pathname === "/usage/steps" || location.pathname === "/eqm/usage/main" 
                                            color: location.pathname === '/usage/day' || location.pathname === '/usage/week' || location.pathname === '/usage/month' || location.pathname === '/usage/year' ? '#fff' : 'black',
                                            backgroundColor: location.pathname === '/usage/day' || location.pathname === '/usage/week' || location.pathname === '/usage/month' || location.pathname === '/usage/year' ? '#598D00' : '',
                                            textDecoration: 'none',
                                            borderRadius: 5,
                                            fontSize: 13
                                        }}>
                                        Usage
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/tanks" className='mx-2 px-3 py-1'
                                        style={{
                                            color: location.pathname === "/tanks" ? '#fff' : 'black',
                                            backgroundColor: location.pathname === "/tanks" ? '#598D00' : '',
                                            textDecoration: 'none',
                                            borderRadius: 5,
                                            fontSize: 13
                                        }} >
                                        Tanks</NavLink>
                                </li>
                            </ul>

                        </div>
                    </div>
                </nav>
            </div>
        </React.Fragment>
    );

}
export default Header

const colourStyles: StylesConfig<ColourOption> = {

    control: (styles) => ({ ...styles, backgroundColor: '#4d5e62', width: 120, height: '30px', minHeight: '30px', }),
    option: (styles) => ({ ...styles, color: 'black', }),
    singleValue: (styles) => ({ ...styles, color: 'white', paddingBottom: 10 }),
    placeholder: (styles) => ({ ...styles, color: 'white', paddingBottom: 9, }),


};


import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import MasterView from "./components/MasterView";
import Dashboard from "./components/SidebarComponents/Dashboard";
import Grower from './components/SidebarComponents/Grower'
import Seeding from './components/SidebarComponents/Seeding'
import Transplanting from './components/SidebarComponents/Transplanting'
import Harvesting from './components/SidebarComponents/Harvesting'
import Tanks from './components/SidebarComponents/Tanks'
import Settings from './components/SidebarComponents/Settings'
import Compliance from './components/SidebarComponents/Compliance'
import Logistics from './components/SidebarComponents/Logistics'
import Sale from './components/SidebarComponents/Sale'
import EQM from './components/SidebarComponents/EQM'
import Overview from './components/HeaderComponents/Overview'
import Nutrients from './components/HeaderComponents/Nutrients'
import Usage from './components/HeaderComponents/Usage'
import UsageSteps from './components/HeaderComponents/UsageSteps'
import UsageStepsCompleted from "./components/HeaderComponents/UsageStepsCompleted";
function App() {
  return (
    <React.Fragment>
      <div >
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<MasterView />} >
              {/* <Route exact path="eqm"> */}
                <Route exact path="dashboard" element={<Dashboard />} />
                <Route exact path="grower" element={<Grower />} />
                <Route exact path="seeding" element={<Seeding />} />
                <Route exact path="transplanting" element={<Transplanting />} />
                <Route exact path="harvesting" element={<Harvesting />} />
                <Route exact path="tanks" element={<Tanks />} />
                <Route exact path="compliance" element={<Compliance />} />
                <Route exact path="logistics" element={<Logistics />} />
                <Route exact path="sale" element={<Sale />} />
                <Route exact path="eqm" element={<EQM />} />
                <Route exact path="settings" element={<Settings />} />
                <Route exact path="overview" element={<Overview />} />
                <Route exact path="nutrients" element={<Nutrients />} />
                <Route exact path="usage" element={<Usage />} />
                <Route exact path="eqm/steps" element={<UsageSteps />} />
                <Route exact path="usage/:slug" element={<UsageStepsCompleted />} />
                
              {/* </Route> */}
            </Route>

          </Routes>
        </BrowserRouter>
      </div>
    </React.Fragment>
  );
}

export default App;

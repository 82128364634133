
import React, { useState } from "react";

import Select, { StylesConfig, components } from 'react-select';
import { BsPlusCircleFill } from 'react-icons/bs';
import '../../components/iconStyle.css'
import { Link } from "react-router-dom";
import { AiFillCaretDown } from 'react-icons/ai'
const firstOptions = [
    { label: 'Option 1', value: 'Option1', },
    { label: 'Option 2', value: 'Option2', },
    { label: 'Option 3', value: 'Option3', },
    { label: 'Option 4', value: 'Option4', }

]
const Plants = () => {
    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <AiFillCaretDown color="#3d4f54" />
            </components.DropdownIndicator>
        );
    };
    const indicatorSeparatorStyle = {
        alignSelf: 'stretch',

    };
    const IndicatorSeparator = ({
        innerProps,
    }: IndicatorSeparatorProps<ColourOption, true>) => {
        return (<span style={indicatorSeparatorStyle} {...innerProps} />);
    };
    return (
        <>

            <h5 className="text-center my-4 "  >Plants </h5>
            {/* <div className="  d-flex justify-content-center  "> */}
                <div className="  d-flex justify-content-center  mt-3">
                    <div className="col-5 m-2">
                        <Select
                            styles={colourStyles}
                            placeholder='Lettuce- Boston Bibb'
                            options={firstOptions}
                            components={{ DropdownIndicator, IndicatorSeparator }}
                        />
                    </div>
                </div>
                <div className="  d-flex justify-content-center  mt-3">
                    <div className="col-5 m-2">
                        <Select
                            styles={colourStyles}
                            placeholder='Lettuce- Red Butter'
                            options={firstOptions}
                            components={{ DropdownIndicator, IndicatorSeparator }}
                        />
                    </div>
                </div>
                <div className="  d-flex justify-content-center  mt-3">
                    <div className="col-5 m-2">
                        <Select
                            styles={colourStyles}
                            placeholder='Lettuce- Romaine'
                            options={firstOptions}
                            components={{ DropdownIndicator, IndicatorSeparator }}
                        />
                    </div>
                </div>
                <div className="  d-flex justify-content-center  mt-3">
                    <div className="col-5 m-2">
                        <Select
                            styles={colourStyles}
                            placeholder='Lettuce- Red Oak'
                            options={firstOptions}
                            components={{ DropdownIndicator, IndicatorSeparator }}
                        />
                    </div>
                </div>
                <div className="  d-flex justify-content-center  mt-3">
                    <div className="col-5  d-flex justify-content-start">
                        <input
                            className=" px-3 my-2"
                        style={{ backgroundColor: '#f7f7f5', borderRadius: 4, height: 35, border: '#7F7F7F solid 2px', borderWidth: 1, outlineColor: '#7b878b', width: 175 }}
                            onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                        onBlur={(e) => e.target.style.outlineColor = '#7b878b'}
                            id="NoTanks"
                            placeholder="Add New Plant"
                            type="text"
                        // onChange={handleText}
                        />
                        <Link to='#' className="mt-2 mx-3" style={{ color: '#598D00' }}>
                            <BsPlusCircleFill />

                        </Link>
                    </div>
                </div>
            {/* </div> */}
           





        </>
    );
};
export default Plants

const colourStyles: StylesConfig<ColourOption> = {

    control: (styles) => ({ ...styles, backgroundColor: '#f7f7f5', width: 220, borderWidth: 2, height: 35, borderColor:'#7F7F7F' }),
    placeholder: (styles) => ({ ...styles, fontSize:13, }),



};
import React, { useState } from "react";
import Multiselect from 'multiselect-react-dropdown';
import makeAnimated from 'react-select/animated';

import { BsEyeSlash, BsEye, } from 'react-icons/bs'
import {
    Form,
    Label,
    Input,
    Col,
    Row,
    Container,
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap'
const firstOptions = [{ name: 'Nutrient 1', id: 'Nutrient1', },
{ name: 'Nutrient 2', id: 'Nutrient2', },
{ name: 'Nutrient 3', id: 'Nutrient3', },
{ name: 'Nutrient 4', id: 'Nutrient4', }

]

const secondOptions = [{ name: 'Ammonium', id: 'Ammonium', },
{ name: 'Calcium', id: 'Calcium', },
{ name: 'Hydrogeen', id: 'Hydrogeen', },
{ name: 'Iron', id: 'Iron', }

]
const Nutrients = () => {
    const [isOpenFirstDrop, setIsOpenFirstDrop] = useState(false)
    const [isOpenSecondDrop, setIsOpenSecondDrop] = useState(false)
    const animatedComponents = makeAnimated();
 
    return (
        <>
            <div className="my-4 row d-flex justify-content-center ">
                <h5 className="text-center mb-5">Nutrients</h5>
             
                    <div className=" col-6 mt-2 " style={{ marginRight: -19 }}>
                        <p style={{ color: '#598D00', fontSize: 13, fontWeight: 500 }}> Monitored Nutrients</p>

                        <div className="my-3 " style={{ width: '80%' }}>
                            <Multiselect
                                options={firstOptions} // Options to display in the dropdown
                                // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                // onSelect={this.onSelect} // Function will trigger on select event
                                // onRemove={this.onRemove} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                placeholder='Select Nutrient'
                                showArrow={true}
                                singleSelect={true}
                                closeIcon={false}

                                style={{
                                    searchBox: { // To change search box element look
                                        fontSize: 12,
                                        minHeight: 30,
                                        maxHeight: 35,
                                        backgroundColor: '#f7f7f5'
                                    },
                                    option: { // To change css for dropdown options
                                        backgroundColor: '#f7f7f5',
                                        color: '#818383'
                                    },
                                    optionContainer: { // To change css for option container 
                                        border: "2 solid",

                                    },

                                }}
                            />
                        </div>
                        <div className="my-3 " style={{ width: '80%' }} >
                            <Multiselect
                                options={secondOptions} // Options to display in the dropdown
                                // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                // onSelect={this.onSelect} // Function will trigger on select event
                                // onRemove={this.onRemove} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                placeholder='Select Nutrients'
                                showArrow={true}
                                showCheckbox={true}
                                style={{
                                    searchBox: { // To change search box element look

                                        fontSize: 12,
                                        minHeight: 30,

                                        backgroundColor: '#f7f7f5'
                                    },
                                    option: { // To change css for dropdown options
                                        backgroundColor: '#f7f7f5',
                                        color: '#818383'
                                    },
                                    optionContainer: { // To change css for option container 
                                        border: "2 solid",

                                    },
                                    // chips: { // To change css chips(Selected options)
                                    //     background: '#598d00'

                                    // },
                                }}
                            />

                        </div>
                        <div className="my-3 " style={{ width: '80%' }}>
                            <Multiselect
                                options={firstOptions} // Options to display in the dropdown
                                // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                // onSelect={this.onSelect} // Function will trigger on select event
                                // onRemove={this.onRemove} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                placeholder='Select Nutrient'
                                showArrow={true}
                                singleSelect={true}
                                closeIcon={false}

                                style={{
                                    searchBox: { // To change search box element look
                                        fontSize: 12,
                                        minHeight: 30,
                                        maxHeight: 35,
                                        backgroundColor: '#f7f7f5'
                                    },
                                    option: { // To change css for dropdown options
                                        backgroundColor: '#f7f7f5',
                                        color: '#818383'
                                    },
                                    optionContainer: { // To change css for option container 
                                        border: "2 solid",

                                    },

                                }}
                            />
                        </div>
                        <div className="my-3 " style={{ width: '80%' }}>
                            <Multiselect
                                options={firstOptions} // Options to display in the dropdown
                                // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                // onSelect={this.onSelect} // Function will trigger on select event
                                // onRemove={this.onRemove} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                placeholder='Select Nutrient'
                                showArrow={true}
                                singleSelect={true}
                                closeIcon={false}

                                style={{
                                    searchBox: { // To change search box element look
                                        fontSize: 12,
                                        minHeight: 30,
                                        maxHeight: 35,
                                        backgroundColor: '#f7f7f5'
                                    },
                                    option: { // To change css for dropdown options
                                        backgroundColor: '#f7f7f5',
                                        color: '#818383'
                                    },
                                    optionContainer: { // To change css for option container 
                                        border: "2 solid",

                                    },

                                }}
                            />
                        </div>



                    </div>
                    <div className=" col-6 mt-2 " style={{ marginLeft: -19 }}>
                        <p style={{ color: '#598D00', fontSize: 13, fontWeight: 500 }}> Residing Tank</p>
                        <div className="my-3">
                            <input
                                className=" px-3 "
                            style={{ backgroundColor: '#f7f7f5', borderRadius: 4, height: 35, border: '#b9bfbf solid 2px', borderWidth: 1, outlineColor: '#7b878b' }}
                                onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                            onBlur={(e) => e.target.style.outlineColor = '#7b878b'}
                                id="NoTanks"
                                placeholder="Tank A"
                                type="text"
                            // onChange={handleText}
                            />
                        </div>
                        <div className="my-3">
                            <input
                                className=" px-3  "
                                style={{ backgroundColor: '#f7f7f5', borderRadius: 4, height: 35, border: '#b9bfbf solid 2px', borderWidth: 1, outlineColor: '#7b878b' }}
                                onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                                onBlur={(e) => e.target.style.outlineColor = '#7b878b'}
                                id="NoTanks"
                                placeholder="Tank B"
                                type="text"
                            // onChange={handleText}
                            />
                        </div>
                        <div className="my-3">
                            <input
                                className=" px-3  "
                                style={{ backgroundColor: '#f7f7f5', borderRadius: 4, height: 35, border: '#b9bfbf solid 2px', borderWidth: 1, outlineColor: '#7b878b' }}
                                onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                                onBlur={(e) => e.target.style.outlineColor = '#7b878b'}
                                id="NoTanks"
                                placeholder="Tank C"
                                type="text"
                            // onChange={handleText}
                            />
                        </div>
                        <div className="my-3">
                            <input
                                className=" px-3  "
                                style={{ backgroundColor: '#f7f7f5', borderRadius: 4, height: 35, border: '#b9bfbf solid 2px', borderWidth: 1, outlineColor: '#7b878b' }}
                                onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                                onBlur={(e) => e.target.style.outlineColor = '#7b878b'}
                                id="NoTanks"
                                placeholder="Tank D"
                                type="text"
                            // onChange={handleText}
                            />
                        </div>

                    </div>
                </div>
                    
               


        </>
    );
};
export default Nutrients


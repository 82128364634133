import React, { useState } from "react";
import {
    Form,
    Label,
    Input,
    Col,
    Row,
    Container,
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap'
const TankSetup = () => {
    return (
        <>  <h5 className="text-center mb-5 mt-2">Tank Setup</h5>
            <div className="my-4 row" style={{ marginRight:144 }}>
               
                <div className=" col-5 text-muted text-end mt-5  ">
                    <Label style={{ fontSize: 13,  }} >No. of Nutrient Tanks</Label>
                </div>
                <div className=" col-4 mt-5 mx-3">
                    <input
                        className=" px-3 "
                     
                        style={{ backgroundColor: '#f7f7f5', borderRadius: 4, height: 35, border: '#b9bfbf solid 2px', borderWidth: 1, outlineColor:'#7b878b' }}
                        onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                        onBlur={(e) => e.target.style.outlineColor = '#7b878b'}
                        id="NoTanks"
                        placeholder="4"
                        type="text"
                    // onChange={handleText}
                    />
                </div>


            </div>
            <div className=" row " style={{ marginRight: 144 }}>
                <div className=" col-5 text-muted text-end ">
                    <Label style={{ fontSize: 13,  }} >Tank Size</Label>

                </div>
                <div className=" col-4 mx-3">
                    <input
                        className=" px-3 "
                        style={{ backgroundColor: '#f7f7f5', borderRadius: 4, height: 35, border: '#b9bfbf solid 2px', borderWidth: 1, outlineColor: '#7b878b' }}
                        onFocus={(e) => e.target.style.outlineColor = '#598D00'}
                        onBlur={(e) => e.target.style.outlineColor = '#7b878b'}
                        id="gal"
                        // value={email}
                        placeholder="50 gal"
                        type="text"
                    // onChange={handleText}
                    />
                </div>


            </div>

        </>
    );
};
export default TankSetup